import { call, put, takeEvery } from 'redux-saga/effects';
import * as action from '../types';
import {_get, _post, _put, _patch, _del, _search} from './common'

export function* groupSaga() {
    yield takeEvery(action.REQUESTS.GROUP.GET.ACTION, _get);
    yield takeEvery(action.REQUESTS.GROUP.POST.ACTION, _post);
    yield takeEvery(action.REQUESTS.GROUP.PUT.ACTION, _put);
    yield takeEvery(action.REQUESTS.GROUP.PATCH.ACTION, _patch);
    yield takeEvery(action.REQUESTS.GROUP.DELETE.ACTION, _del);
    yield takeEvery(action.REQUESTS.GROUP.SEARCH.ACTION, _search);
}

export function* moduleSaga() {
    yield takeEvery(action.REQUESTS.MODULE.GET.ACTION, _get);
    yield takeEvery(action.REQUESTS.MODULE.POST.ACTION, _post);
    yield takeEvery(action.REQUESTS.MODULE.PUT.ACTION, _put);
    yield takeEvery(action.REQUESTS.MODULE.PATCH.ACTION, _patch);
    yield takeEvery(action.REQUESTS.MODULE.DELETE.ACTION, _del);
    yield takeEvery(action.REQUESTS.MODULE.SEARCH.ACTION, _search);
}
export function* userSaga() {
    yield takeEvery(action.REQUESTS.USER.GET.ACTION, _get);
    yield takeEvery(action.REQUESTS.USER.POST.ACTION, _post);
    yield takeEvery(action.REQUESTS.USER.PUT.ACTION, _put);
    yield takeEvery(action.REQUESTS.USER.PATCH.ACTION, _patch);
    yield takeEvery(action.REQUESTS.USER.DELETE.ACTION, _del);
    yield takeEvery(action.REQUESTS.USER.SEARCH.ACTION, _search);
}

export function* dashBoardSaga() {
    yield takeEvery(action.REQUESTS.DASHBOARD.GET.ACTION, _get);
    yield takeEvery(action.REQUESTS.DASHBOARD.POST.ACTION, _post);
    yield takeEvery(action.REQUESTS.DASHBOARD.PUT.ACTION, _put);
    yield takeEvery(action.REQUESTS.DASHBOARD.PATCH.ACTION, _patch);
    yield takeEvery(action.REQUESTS.DASHBOARD.DELETE.ACTION, _del);
    yield takeEvery(action.REQUESTS.DASHBOARD.SEARCH.ACTION, _search);
}

export function* chartSaga() {
    yield takeEvery(action.REQUESTS.CHART.GET.ACTION, _get);
    yield takeEvery(action.REQUESTS.CHART.POST.ACTION, _post);
    yield takeEvery(action.REQUESTS.CHART.PUT.ACTION, _put);
    yield takeEvery(action.REQUESTS.CHART.PATCH.ACTION, _patch);
    yield takeEvery(action.REQUESTS.CHART.DELETE.ACTION, _del);
    yield takeEvery(action.REQUESTS.CHART.SEARCH.ACTION, _search);
}

export function* companySaga() {
    yield takeEvery(action.REQUESTS.COMPANY.GET.ACTION, _get);
    yield takeEvery(action.REQUESTS.COMPANY.POST.ACTION, _post);
    yield takeEvery(action.REQUESTS.COMPANY.PUT.ACTION, _put);
    yield takeEvery(action.REQUESTS.COMPANY.PATCH.ACTION, _patch);
    yield takeEvery(action.REQUESTS.COMPANY.DELETE.ACTION, _del);
    yield takeEvery(action.REQUESTS.COMPANY.SEARCH.ACTION, _search);
}

export function* livecameraSaga() {
    yield takeEvery(action.REQUESTS.LIVECAM.GET.ACTION, _get);
    yield takeEvery(action.REQUESTS.LIVECAM.POST.ACTION, _post);
    yield takeEvery(action.REQUESTS.LIVECAM.PUT.ACTION, _put);
    yield takeEvery(action.REQUESTS.LIVECAM.PATCH.ACTION, _patch);
    yield takeEvery(action.REQUESTS.LIVECAM.DELETE.ACTION, _del);
    yield takeEvery(action.REQUESTS.LIVECAM.SEARCH.ACTION, _search);
}

export function* timelapsecameraSaga() {
    yield takeEvery(action.REQUESTS.TIMELAPSE.GET.ACTION, _get);
    yield takeEvery(action.REQUESTS.TIMELAPSE.POST.ACTION, _post);
    yield takeEvery(action.REQUESTS.TIMELAPSE.PUT.ACTION, _put);
    yield takeEvery(action.REQUESTS.TIMELAPSE.PATCH.ACTION, _patch);
    yield takeEvery(action.REQUESTS.TIMELAPSE.DELETE.ACTION, _del);
    yield takeEvery(action.REQUESTS.TIMELAPSE.SEARCH.ACTION, _search);
}

export function* projectSaga() {
    yield takeEvery(action.REQUESTS.PROJECT.GET.ACTION, _get);
    yield takeEvery(action.REQUESTS.PROJECT.POST.ACTION, _post);
    yield takeEvery(action.REQUESTS.PROJECT.PUT.ACTION, _put);
    yield takeEvery(action.REQUESTS.PROJECT.PATCH.ACTION, _patch);
    yield takeEvery(action.REQUESTS.PROJECT.DELETE.ACTION, _del);
    yield takeEvery(action.REQUESTS.PROJECT.SEARCH.ACTION, _search);
}

export function* permissionSaga() {
    yield takeEvery(action.REQUESTS.PERMISSION.GET.ACTION, _get);
    yield takeEvery(action.REQUESTS.PERMISSION.POST.ACTION, _post);
    yield takeEvery(action.REQUESTS.PERMISSION.PUT.ACTION, _put);
    yield takeEvery(action.REQUESTS.PERMISSION.PATCH.ACTION, _patch);
    yield takeEvery(action.REQUESTS.PERMISSION.DELETE.ACTION, _del);
    yield takeEvery(action.REQUESTS.PERMISSION.SEARCH.ACTION, _search);
}

export function* dbPlanSaga() {
    yield takeEvery(action.REQUESTS.USER.GET.ACTION, _get);
    yield takeEvery(action.REQUESTS.USER.POST.ACTION, _post);
    yield takeEvery(action.REQUESTS.USER.PUT.ACTION, _put);
    yield takeEvery(action.REQUESTS.USER.PATCH.ACTION, _patch);
    yield takeEvery(action.REQUESTS.USER.DELETE.ACTION, _del);
    yield takeEvery(action.REQUESTS.USER.SEARCH.ACTION, _search);
}

export function* themeSaga() {
    yield takeEvery(action.REQUESTS.THEME.GET.ACTION, _get);
    yield takeEvery(action.REQUESTS.THEME.POST.ACTION, _post);
    yield takeEvery(action.REQUESTS.THEME.PUT.ACTION, _put);
    yield takeEvery(action.REQUESTS.THEME.PATCH.ACTION, _patch);
    yield takeEvery(action.REQUESTS.THEME.DELETE.ACTION, _del);
    yield takeEvery(action.REQUESTS.THEME.SEARCH.ACTION, _search);
}
export function* shopSaga() {
    yield takeEvery(action.REQUESTS.CENTER.GET.ACTION, _get);
    yield takeEvery(action.REQUESTS.CENTER.POST.ACTION, _post);
    yield takeEvery(action.REQUESTS.CENTER.PUT.ACTION, _put);
    yield takeEvery(action.REQUESTS.CENTER.PATCH.ACTION, _patch);
    yield takeEvery(action.REQUESTS.CENTER.DELETE.ACTION, _del);
    yield takeEvery(action.REQUESTS.CENTER.SEARCH.ACTION, _search);
}
export function* locationSaga() {
    yield takeEvery(action.REQUESTS.LOCATION.GET.ACTION, _get);
    yield takeEvery(action.REQUESTS.LOCATION.POST.ACTION, _post);
    yield takeEvery(action.REQUESTS.LOCATION.PUT.ACTION, _put);
    yield takeEvery(action.REQUESTS.LOCATION.PATCH.ACTION, _patch);
    yield takeEvery(action.REQUESTS.LOCATION.DELETE.ACTION, _del);
    yield takeEvery(action.REQUESTS.LOCATION.SEARCH.ACTION, _search);
}

export function* geoLocationSaga() {
    yield takeEvery(action.REQUESTS.GEOLOCATION.GET.ACTION, _get);
    yield takeEvery(action.REQUESTS.GEOLOCATION.POST.ACTION, _post);
    yield takeEvery(action.REQUESTS.GEOLOCATION.PUT.ACTION, _put);
    yield takeEvery(action.REQUESTS.GEOLOCATION.PATCH.ACTION, _patch);
    yield takeEvery(action.REQUESTS.GEOLOCATION.DELETE.ACTION, _del);
    yield takeEvery(action.REQUESTS.GEOLOCATION.SEARCH.ACTION, _search);
}
export function* productSaga() {
    yield takeEvery(action.REQUESTS.PRODUCT.GET.ACTION, _get);
    yield takeEvery(action.REQUESTS.PRODUCT.POST.ACTION, _post);
    yield takeEvery(action.REQUESTS.PRODUCT.PUT.ACTION, _put);
    yield takeEvery(action.REQUESTS.PRODUCT.PATCH.ACTION, _patch);
    yield takeEvery(action.REQUESTS.PRODUCT.DELETE.ACTION, _del);
    yield takeEvery(action.REQUESTS.PRODUCT.SEARCH.ACTION, _search);
}
export function* stockSaga() {
    yield takeEvery(action.REQUESTS.STOCK.GET.ACTION, _get);
    yield takeEvery(action.REQUESTS.STOCK.POST.ACTION, _post);
    yield takeEvery(action.REQUESTS.STOCK.PUT.ACTION, _put);
    yield takeEvery(action.REQUESTS.STOCK.PATCH.ACTION, _patch);
    yield takeEvery(action.REQUESTS.STOCK.DELETE.ACTION, _del);
    yield takeEvery(action.REQUESTS.STOCK.SEARCH.ACTION, _search);
}
export function* saleSaga() {
    yield takeEvery(action.REQUESTS.SALE.GET.ACTION, _get);
    yield takeEvery(action.REQUESTS.SALE.POST.ACTION, _post);
    yield takeEvery(action.REQUESTS.SALE.PUT.ACTION, _put);
    yield takeEvery(action.REQUESTS.SALE.PATCH.ACTION, _patch);
    yield takeEvery(action.REQUESTS.SALE.DELETE.ACTION, _del);
    yield takeEvery(action.REQUESTS.SALE.SEARCH.ACTION, _search);
}

