import { Card, Container, Grid, Typography, createTheme } from '@mui/material'
import React from 'react'
import ZCard from '../../ZCard/ZCard'
import ZTable from '../../ZTable/ZTable'
import { LandinStyles } from './Styles'
import ReportOffRounded from '@mui/icons-material/ReportOffRounded'
import { AccountBoxSharp, LocationCity, PaymentTwoTone } from '@mui/icons-material'
import { color_Theme } from '../../colors'
import { ThemeProvider } from '@emotion/react';
import { useNavigate } from 'react-router-dom'
import bgImage from '../assets/mainBG.jpg'
import NewCard from '../../NewCard/Newcard'
import location from '../assets/locationreport.jpg'
import payment from '../assets/paymentreport2.jpg'



const LandingPage = () => {
    const navigate = useNavigate()
    const defaultTheme = createTheme({
        typography: {
            fontFamily: ['Poppins', 'sans-serif'].join(','),
            justifyContent: 'center',
            alignItems: 'center'
        },
    })
    return (
        <ThemeProvider theme={defaultTheme}>
            <div style={{
                display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', backgroundImage: `url(${bgImage})`, // Fix the background image URL
                backgroundSize: 'cover'
            }}>
                <div style={{display:'flex',flexDirection:'column'}}>
                <Grid sx={LandinStyles.container} spacing={2}>
                    <Grid sm={12} md={6} xs={6}>
                        <NewCard image={location} title={'Location Report'} onClick={() => navigate('/location/')} />

                    </Grid>
                    <Grid>
                        <NewCard image={payment} title={'Payment Report'} onClick={() => navigate('/landing/')} />

                    </Grid>

                </Grid>
               
                </div>
            </div>
            
        </ThemeProvider>
    )
}

export default LandingPage