import * as React from 'react';
import { TextField, Button, Snackbar, Alert } from '@mui/material';
import XForm from '../../inputs/XForm';
import Stack from '@mui/system/Stack';
import { Card, Box, Grid, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import { REQUESTS } from '../../../redux/types';
import CircularProgress from '@mui/material/CircularProgress';
import XCard from '../../basic/XCard';
import { PlusOne } from '@mui/icons-material';
import { Link,useNavigate ,createSearchParams} from "react-router-dom";
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';


const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

export default function Product({ data, onSubmit, children }) {
    let dispatch = useDispatch();
    let navigate = useNavigate()
    let productState = useSelector((state) => state.product)

    const init = () => {
        dispatch({
            type: REQUESTS.LOCATION.SEARCH.ACTION, payload: "forform/0/", callback: (resp) => {
            }
        })
    }
    React.useEffect(() => {

        dispatch({
            type: REQUESTS.PRODUCT.GET.ACTION, payload: 0
        })
        init()
    }, [])
    React.useEffect(() => {
        init()
    }, [productState.data])
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

    };

    const edit = (data) => {
        navigate({pathname:"/product_add/",
                search: createSearchParams({"data":JSON.stringify(data)}).toString(),
            });
    }
    return (<>
        <Grid
            container
            columns={{ xs: 1, md: 2 }}
            xs={{ margin: 'auto', }}
            alignItems="center"
            justifyContent="center"
        >
            <Grid xs={1}>
                <Card sx={{ display: 'flex', alignItems: "center", marginBottom: 2,p:2 }}>
                    <div style={{ display: 'flex', marginLeft: 10, fontSize: 14, fontFamily: 'sans-serif', flexDirection: 'column', width: "100%" }}>
                        PRODUCT
                    </div>
                    <div style={{ marginRight: 10 }}>
                        <Link
                            exact={"true"}
                            to={"/product_add/"}
                            className={`menu-item`}
                        > <AddCircleOutlineOutlinedIcon sx={{ fontSize: 18,color:'green' }} /></Link>
                    </div>
                </Card>
            </Grid>
        </Grid>
        <Grid
            container
            columns={{ xs: 1, md: 2 }}
            xs={{ margin: 'auto', }}
            alignItems="center"
            justifyContent="center"

        >
            <Grid xs={1} sx={{ overflowY: 'auto', maxHeight: '88vh' }}>
                {productState.data.results && productState.data.results.map((data,i) => {
                    return <XCard key={i} header={{ title: data.name }} onClick={()=>{edit(data)}}/>
                })}
            </Grid>
        </Grid>
    </>
    );
}