import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';

function ZCard({ subTitle, Title, onClick, children, bgcolor, color, hoverColor,marginRight }) {
    return (
        <Card sx={{
            width: '20%',
            height: '50%',
            borderRadius: '10px',
            bgcolor,
            marginRight,
            transition: 'background-color 0.3s',
            '&:hover': {
                bgcolor: hoverColor,
            },
        }} elevation={4}>
            <CardContent onClick={onClick} style={{
                cursor: 'pointer',
                display: 'flex', justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column'
            }}>
                {Title&&(
                    <Typography variant="body2" color="black" component="animate">
                    {Title}
                </Typography>
                )}
                
                <div style={{ display: 'flex', flexDirection: 'row', marginTop: '10px' }}>

                    {children}
                    {subTitle && (
                        <Typography variant="body2" color="textSecondary" component="p" sx={{
                            fontWeight: 'bold', fontSize: '16px',
                            color: '#000', display: 'flex', marginLeft: '10px'
                        }}>
                            {subTitle}
                        </Typography>
                    )}

                </div>
            </CardContent>
        </Card>
    );
}

export default ZCard;
