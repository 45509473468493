import * as React from "react";
import Box from "@mui/material/Box";
import {
  FormControlLabel,
  Checkbox,
  TextField,
  Button,
  Icon,
  Grid,
  Paper,
  Avatar,
  Typography,
  Link,
} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import XForm from "../../inputs/XForm";
import { LOGIN_REQUEST } from "../../../redux/types";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

const Login = ({ title, hasForgot, hasRegister, submitLabel }) => {
  title = title ? title : "Sign In";
  submitLabel = submitLabel ? submitLabel : "Sign In";
  let navigate = useNavigate();
  let dispatch = useDispatch();

  const paperStyle = {
    padding: 20,
    height: "70vh",
    width: 380,
    margin: "20px auto",
  };
  const avatarStyle = { backgroundColor: "#1095bbfc" };
  const btnStyle = { margin: "8px 0px" };
  return (
    <Grid>
      <Paper elevation={10} style={paperStyle}>
        <Grid align="center">
          <Avatar style={avatarStyle}>
            <LockOutlinedIcon />
          </Avatar>
          <h2>{title}</h2>
        </Grid>

        <XForm
          onSubmit={(e, data) => {
            dispatch({
              type: LOGIN_REQUEST,
              payload: data,
              navigate: navigate,
            });
            console.log(data);
          }}
        >
          <TextField
            fullWidth
            required
            variant="standard"
            label="Username"
            name="username"
            placeholder="Enter Username"
          />
          <TextField
            fullWidth
            required
            variant="standard"
            label="Password"
            name="password"
            placeholder="Enter Password"
            type="password"
          />

          <FormControlLabel
            control={<Checkbox defaultChecked />}
            label="Remember Me"
          />

          <Button
            fullWidth
            variant="contained"
            type="submit"
            color="primary"
            style={btnStyle}
          >
            {submitLabel}
          </Button>
        </XForm>

        {hasForgot && (
          <Typography>
            <Link href="#">Forgot Password ?</Link>
          </Typography>
        )}

        {hasRegister && (
          <Typography>
            {" "}
            Do you have an account ?<Link href="#">Sign Up</Link>
          </Typography>
        )}
      </Paper>
    </Grid>
  );
};
export default Login;
