import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import XSideBar from './XSideBar';
import { Routes, Route, useNavigate } from "react-router-dom";
import { menuItems, HomePage } from '../layout/menus'
import { useDispatch, useSelector } from 'react-redux';
import { REQUESTS, LOGOUT_REQUEST } from '../../redux/types';
import Login from '../ui/auth/Login';
import ZToolbar from '../layout/ZToolbar'
import Error404 from '../ui/error/Error404'
import { Height } from '@mui/icons-material';
import Location from '../ui/LocationReport/Location';
import LandingPage from '../ui/LandingPage/LandingPage';

const drawerWidth = 50;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: 4,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  }),
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    // marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  root: {
    minWidth: 0,
    '@media (min-width: 0px)': {
      minHeight: 0
    }
  },
  '@media (min-width: 0px)': {
    minHeight: 0
  },
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
  color: 'blue',
  margin: 0,
  height: 37
}));

export default function Layout(props) {
  let subMenus = []
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" open={open} >
        <ZToolbar handleDrawerOpen={handleDrawerOpen} open={open} />
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >

        <DrawerHeader>
          <div >
            <span onClick={handleDrawerClose}>
              {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </span>
          </div>
        </DrawerHeader>
        <Divider />
        {props.isLogged && <XSideBar menuItems={menuItems} />}
      </Drawer>
      <Main open={open}>
        <div style={{ height: 40 }}></div>
        <Routes>
          <Route exact={"true"} path={"/"} element={<HomePage />}></Route>
          <Route exact={"true"} path={"/Location"} element={<Location />}></Route>
          <Route exact={"true"} path={"/Landing"} element={<LandingPage />}></Route>
          <Route exact={"true"} path={"/login"} element={<Login />}></Route>
          {
            menuItems.map((menu, i) => {
              if (menu.subMenus) {
                subMenus = subMenus.concat(menu.subMenus)
              }
              return <Route key={i} exact={"true"} path={menu.to} element={menu.component}></Route>
            })
          }
          {
            subMenus.map((menu, i) => {
              return <Route key={i} exact={"true"} path={menu.to} element={menu.component}></Route>
            })
          }
          <Route path={"*"} element={<Error404 />}></Route>
        </Routes>
      </Main>
    </Box>
  );
}