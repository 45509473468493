import * as React from 'react';
import { TextField, Button, Snackbar, Alert, autocompleteClasses } from '@mui/material';
import XForm from '../../inputs/XForm';
import { styled } from '@mui/material/styles';
import { useDispatch } from 'react-redux';
import { REQUESTS } from '../../../redux/types';
import XGeoMove from '../../inputs/XGeoMove';
import AddLocationIcon from '@mui/icons-material/AddLocation';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import LoginIcon from '@mui/icons-material/Login';
import ZAccordion from '../../basic/ZAccordion';
import CircularProgress from '@mui/material/CircularProgress';
import { DataObjectRounded } from '@mui/icons-material';
import { Link, useNavigate, createSearchParams } from "react-router-dom";
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Paper from '@mui/material/Paper';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import XLocation from '../../inputs/XLocation';
import Inventory from './Inventory';


const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(.5),
    textAlign: 'left',
    color: "#e6f9ff",
    border: 'none',

}));

export default function Home() {
    let dispatch = useDispatch();
    let navigate = useNavigate()
    const searchRef = React.createRef()
    let [company, setCompany] = React.useState([])
    let [loading, setLoading] = React.useState(true)
    const [message, setMessage] = React.useState()
    const [dyForm, setDyForm] = React.useState([])
    const [status, setStatus] = React.useState({})
    const [show, setShow] = React.useState(false)
    const [name, setName] = React.useState()
    const [location, setLocation] = React.useState()


    const init = (latitude, longitude) => {
        
        console.log("search data:", localStorage.search_shop)
        
        if (localStorage.search_shop && localStorage.search_shop.length > 0) {
            search(localStorage.search_shop)
            return 0
        } else {
            setLoading(true)
            dispatch({
                type: REQUESTS.COMPANY.SEARCH.ACTION,
                payload: `/nearest/${latitude}/${longitude}/`,
                callback: (data) => {
                    setLoading(false)
                    setCompany(data)
                }
            })
        }

    }
    const search = (search) => {
        setLoading(true)
        if (search.length > 0) {
            setTimeout(() => {
                dispatch({
                    type: REQUESTS.COMPANY.SEARCH.ACTION,
                    payload: `/company/search/${search}/`,
                    callback: (data) => {
                        setCompany(data)
                        setLoading(false)
                    }
                })
            }, 200)
        }
    }
    const getLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                init(position.coords.latitude, position.coords.longitude)
            });
        } else {
            init(0, 0)
        }
    }
    React.useEffect(() => {
        getLocation()
    }, [])
    React.useEffect(() => {
    }, [dyForm])
    React.useEffect(() => {

        if (company && company.length == 0) {
            init(0, 0)
        }
        if (navigator.geolocation && searchRef.current && searchRef.current.value && searchRef.current.value.length == 0) {
            navigator.geolocation.getCurrentPosition((position) => {
                init(position.coords.latitude, position.coords.longitude)
            });
        }
    }, [company])
    const prepareFormData = (e, data, callback) => {
        setLoading(true)
        let formData = []
        for (var i = 0; i < parseInt(data.size); i++) {
            if (data["item_count$" + i] && data["item_count$" + i] != 0 ||
                (data["paid_amount$" + i] && data["paid_amount$" + i] != 0)) {
                let ob = {}
                ob["product_id"] = data["product_id$" + i]
                ob["company_id"] = data["company_id$" + i]
                ob["no_of_item"] = data["item_count$" + i]
                ob["rate"] = data["rate$" + i]
                ob["amount"] = data["amount$" + i]
                ob["_date"] = data["_date$" + i]
                ob["paid_amount"] = data["paid_amount$" + i] ? data["paid_amount$" + i] : 0
                formData.push(ob)
            }
        }
        setMessage(null)
        console.log("FORM DATA:", formData)
        dispatch({
            type: REQUESTS.PRODUCT.POST.ACTION,
            payload: { url: `inventory/`, payload: formData },
            callback: (response) => {
                console.log("response", response)
                setDyForm(response.data)
                setShow(true)
                setLoading(false)
            }, no_update: true
        })
    }

    const getProducts = (c) => {
        setName(c.name)
        dispatch({
            type: REQUESTS.COMPANY.SEARCH.ACTION,
            payload: `inventory/${c.id}/`,
            callback: (response) => {
                console.log("AMOUNT STATUS", response.data.status)
                setDyForm(response.data)
                setShow(true)
            }
            , no_update: true
        })
    }

    return (<>

        <Grid
            container
            columns={{ xs: 1, md: 3 }}
            xs={{ margin: 'auto', }}
            alignItems="center"
            justifyContent="center"

        >
            <Grid xs={1}>
                <Inventory name={name} show={show} handleClose={() => { setShow(false) }} data={dyForm} updateData={prepareFormData} loading={loading} />
                <Stack spacing={0.6} sx={{ mb: 0.5 }}>
                    <Card sx={{ display: 'flex', alignItems: "center", background: '#006080', color: '#ffff' }}>
                        <div style={{ display: 'flex', marginLeft: 10, fontSize: 18, fontFamily: 'sans-serif', flexDirection: 'column', width: "100%" }}>
                            NEAREST SKS OUTLETS
                        </div>
                        <div style={{ marginRight: 10 }}>
                            <XGeoMove onChange={(latitude, longitude, move) => {
                                init(latitude, longitude)
                            }} />
                        </div>

                    </Card>

                </Stack>
                <Stack spacing={0.6} sx={{ mb: 0.5 }}>
                    <Card sx={{ p: 1, background: '#006080', color: '#ffff' }}>
                        <TextField
                            label="Search shop"
                            id="outlined-size-small"
                            size="small"
                            fullWidth
                            value={localStorage.search_shop}
                            onInput={(e) => { localStorage.search_shop = e.target.value;search(e.target.value)}}
                            sx={{ color: 'red' }}
                        />
                    </Card>
                </Stack>
                <Stack
                    sx={{ overflowY: 'auto', maxHeight: '74vh' }}
                    spacing={0.6}
                >
                    {company && company.map((c) => {
                        let map = "https://www.google.com/maps/@" + c.latitude + "," + c.longitude + ",15z?entry=ttu"
                        return (<>
                            <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
                                <Box sx={{ my: 3, mx: 2 }}>
                                    <Grid container alignItems="center">
                                        <Grid item xs>
                                            <Typography gutterBottom variant="h6" component="div">
                                                {c.name}
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography gutterBottom variant="h6" component="div">
                                                <ArrowCircleRightIcon sx={{ cursor: 'pointer' }} onClick={() => { getProducts(c) }} />
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Typography color="text.secondary" variant="body2">
                                        {c.place}
                                        {location}
                                    </Typography>
                                </Box>
                                <Divider variant="middle" />
                                <Box sx={{ m: 1 }}>
                                    <Stack direction="row" spacing={1}>
                                        <XLocation onClick={(position) => {
                                            dispatch({
                                                type: REQUESTS.GEOLOCATION.POST.ACTION,
                                                payload: { latitude: position.coords.latitude, longitude: position.coords.longitude, id: c.id },
                                                callback: (data) => {
                                                    setLocation(data["locdata"])
                                                },
                                                no_update: true
                                            })
                                        }} />
                                        <Chip color="primary" label="Soft" />
                                        <Chip label="Medium" />
                                        <Chip label="Hard" />
                                    </Stack>
                                </Box>
                            </Box>
                        </>)
                    })}
                </Stack>
            </Grid>

        </Grid>
    </>
    );
}