import * as React from 'react';
import { TextField, Button, Snackbar, Alert } from '@mui/material';
import XForm from '../../inputs/XForm';
import Stack from '@mui/system/Stack';
import { Card, Box, Grid, Paper } from '@mui/material';
import XAutoComplete from '../../inputs/XAutoComplate';
import { styled } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import { REQUESTS } from '../../../redux/types';
import XLocation from '../../inputs/XLocation';
import { green } from '@mui/material/colors';
import CircularProgress from '@mui/material/CircularProgress';


const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

export default function Register({ data, onSubmit, children }) {
    let dispatch = useDispatch();
    let center = useSelector(state => state.center)
    let geolocation = useSelector(state => state.geolocation)
    // let center = useSelector(state => state.center)
    const [company, setCompany] = React.useState([])
    const [place, setPlace] = React.useState([])
    const [address, setAddress] = React.useState('')
    const [loading, setLoading] = React.useState(false)
    const [message, setMessage] = React.useState("")

    const init = () => {
        dispatch({
            type: REQUESTS.LOCATION.SEARCH.ACTION, payload: "company/place/0/", callback: (resp) => {
                setCompany(resp.data.companies)
                setPlace(resp.data.places)
            }
        })
    }
    React.useEffect(() => {
        init()
    }, [])
    React.useEffect(() => {
        init()
    }, [center, address])
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setMessage("");
    };

    return (<>
        <Grid
            container
            columns={{ xs: 1, md: 3 }}
            xs={{ margin: 'auto', }}
            alignItems="center"
            justifyContent="center"

        >
            <Grid xs={1}>
                <XForm onSubmit={(e, data) => {
                    setLoading(true)
                    dispatch({
                        type: REQUESTS.LOCATION.POST.ACTION, payload: data, callback: (resp) => {
                            console.log("Data:", resp)
                            init()
                            setLoading(false)
                            if (resp.error) {
                                setMessage("Updation failed")
                            } else {
                                setMessage("Updation Success")
                            }

                        }, no_update: true
                    })
                    console.log(data)
                }}>
                    <Stack spacing={0.8}>
                        <Item >
                            <Paper variant='success'>
                                SHOP DETAILS
                            </Paper>
                            <Snackbar sx={{ display: 'flex', justifyContent: 'center' }} open={message != ""} autoHideDuration={2000} onClose={() => handleClose}>
                                <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                                    This is a success message!
                                </Alert>
                            </Snackbar>
                        </Item>
                        <Item>
                            <Grid container spacing={1}>
                                <Grid item xs={10}>
                                    <XAutoComplete
                                        
                                        initVal={address}
                                        options={place}
                                        data_label={"label"} data_value={"value"}
                                        label="Place"
                                        name="place"
                                        style={{ width: "100%" }}
                                        onChange={(label, value) => {
                                            REQUESTS.GEOLOCATION.POST["URL"] = "geo/search"
                                            if (label == "input") {
                                                dispatch({
                                                    type: REQUESTS.GEOLOCATION.POST.ACTION,
                                                    payload: { address: value },
                                                    callback: (data) => {
                                                        if (data["address"]) {
                                                            place.push({ label: data["address"], value: data["address"] })
                                                            setPlace(place)
                                                        }
                                                    },
                                                    no_update: true
                                                })
                                            }
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <XLocation onClick={(position) => {
                                        dispatch({
                                            type: REQUESTS.GEOLOCATION.POST.ACTION,
                                            payload: { latitude: position.coords.latitude, longitude: position.coords.longitude },
                                            callback: (data) => {
                                                place.push({ label: data["locdata"], value: data["locdata"] })
                                                setPlace(place)
                                                setAddress({ label: data["locdata"], value: data["locdata"] })
                                            },
                                            no_update: true
                                        })
                                    }} />
                                </Grid>
                            </Grid>


                        </Item>

                        <Item>
                            <XAutoComplete
                                options={company}
                                data_label={"label"} data_value={"label"}
                                label="Shop"
                                name="name"
                                style={{ width: "100%" }}
                            />
                        </Item>
                        <Item>
                            <TextField
                                required
                                id="phone"
                                label="Phone"
                                name="phone"
                                style={{ width: "100%" }}
                            />
                        </Item>
                        <Item>
                            <TextField
                                required
                                id="email"
                                label="Email"
                                name="email"
                                style={{ width: "100%" }}
                            />
                        </Item>
                        <Item>
                            <TextField
                                required
                                id="gst_number"
                                label="Gst Number"
                                name="gst_no"
                                style={{ width: "100%" }}
                            />
                        </Item>
                        <Item>
                            <Button type={"reset"}>Reset</Button>
                            <Button sx={{ color: green[500] }} type={"submit"}>Save</Button>
                            {loading && <CircularProgress size={20} />}
                        </Item>
                    </Stack>
                </XForm>
            </Grid>
        </Grid>
    </>
    );
}