import { FILL, APPEND, REPLACE, REMOVE } from '../types'
const PROJECT_NAME = "bglive"

export const cachedData = (storeName, rtnType = "array") => {
    let re = rtnType == "array" ? [] : {}
    storeName = PROJECT_NAME + "_" + storeName
    try {
        re = JSON.parse(localStorage.getItem(storeName)) || []
    } catch (e) {

    }
    console.log(storeName, re)
    return re

}
export const updateCacheData = (storeName, prev1, data, action) => {
    storeName = PROJECT_NAME + "_" + storeName
    let prev = localStorage.getItem(storeName) ? localStorage.getItem(storeName) : {}
    if (action === APPEND) {
        prev.results.unshift(data)
    }
    if (action === REPLACE) {
        prev.results = prev.results.map((d) => {
            return d.id === data.id ? data : d
        })
    }
    if (action === REMOVE) {
        prev.results = prev.results.filter((d) => {
            return d.id !== data
        })
    }
    if (action === FILL) {
        prev = data
    }
    localStorage.setItem(storeName, JSON.stringify(prev))
    return prev
}