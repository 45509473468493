import * as React from 'react';
import { TextField, Button, Snackbar, Alert, autocompleteClasses, CircularProgress,Chip } from '@mui/material';
import XForm from '../../inputs/XForm';
import Stack from '@mui/system/Stack';
import { Card, Grid, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import { REQUESTS } from '../../../redux/types';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Link } from "react-router-dom";
import ZTable from '../../basic/ZTable';
import ViewSave from '../common/ViewSave';
import axios from 'axios'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';

const ZCard = ({ head, number, text, btn }) => {
    return <Card>
        <CardContent>
            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                {head}
            </Typography>
            <Typography variant="h5" component="div">
                {number}
            </Typography>
            <Typography sx={{ mb: 1 }} color="text.secondary">
                {text}
            </Typography>
        </CardContent>
        <CardActions>
            <Button size="small">{btn}</Button>
        </CardActions>
    </Card>
}

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'left',
    color: theme.palette.text.secondary,

}));

export default function Home() {
    let dispatch = useDispatch();
    let salesState = useSelector(state => state.sale)
    let [report, setReport] = React.useState([])
    let [pagination, setPagination] = React.useState({})
    const [message, setMessage] = React.useState("")
    const [progress, setProgress] = React.useState(1)
    let fromRef = React.createRef()
    let toRef = React.createRef()
    let collection_amount = 0


    React.useEffect(() => { collection_amount=0;fetchData(new Date().toJSON().slice(0, 10),new Date().toJSON().slice(0, 10)) }, [])
    React.useEffect(() => {collection_amount=0 }, [report])

    const fetchData = (fromDate, toDate) => {

        let filter = { created_at__contains: new Date().toJSON().slice(0, 10) }
        if (fromDate == toDate) {
            filter = { created_at__gte: fromDate}
        } else if (fromDate && toDate) {
            filter = { created_at__gte: fromDate, created_at__lte: toDate }
        }
        axios.post('payment/reports/', filter)
            .then(response => {
                setReport(response.data.results);
                setPagination({ count: response.data.count, next: response.data.next, previous: response.data.previous });
                console.log("REPORTS:", response.data)
            });
    }

    const download = (fromDate, toDate) => {
        setProgress(0)
        let filter = { created_at__contains: new Date().toJSON().slice(0, 10) }
        if (fromDate == toDate) {
            filter = { created_at__contains: fromDate }
        } else if (fromDate && toDate) {
            filter = { created_at__gte: fromDate, created_at__lte: toDate }
        }
        console.log("filter", filter)
        const options = {
            // Defines options for request
            responseType: 'blob',
            // For a file (e.g. image, audio), response should be read to Blob (default to JS object from JSON)
            onDownloadProgress: function (progressEvent) {
                // Function fires when there is download progress
                console.log(Math.floor(progressEvent.total / progressEvent.loaded));
                console.log(progressEvent.loaded, progressEvent.total);
                setProgress(Math.floor(progressEvent.total / progressEvent.loaded));
                // Logs percentage complete to the console
            }
        }
        let fields = "created_at,payment_method,order_id,paid_amount,payment_status,receipt_no,loan,customer,customer_phone"
        filter = JSON.stringify(filter)
        console.log("url", `https://www.vikasmoneyeconnect.com/api/report/0/transactions/${filter}/-created_at/${fields}/1/`)

        axios.get(`https://www.vikasmoneyeconnect.com/api/report/0/transactions/${filter}/-created_at/${fields}/1/`, options)
            .then(function (response) {
                console.log("PROGRESS", response.headers)
                let fileName = `V Pay Report ${fromDate}_${toDate}.xlsx` //response.headers["content-disposition"].split("filename=")[1];
                if (window.navigator && window.navigator.msSaveOrOpenBlob) { // IE variant
                    window.navigator.msSaveOrOpenBlob(new Blob([response.data],
                        { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }
                    ),
                        fileName
                    );
                } else {
                    const url = window.URL.createObjectURL(new Blob([response.data],
                        { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', fileName)
                    // response.headers["content-disposition"].split("filename=")[1]);
                    document.body.appendChild(link);
                    link.click();
                }
            });
    }

    const ThCss = {
        fontWeight: 700,
        fontSize: 10,
        background: '#E6E9EE',
        paddingLeft: 4, paddingRight: 4
    }
    const cellCss = {
        padding: 4,
        borderBottom: '0.5px solid #d9dcde',
        fontSize: 12,
        paddingLeft: 10,
        background: '#F4F6F9'

    }
    const renderHead = () => <thead style={{ position: "sticky", top: 0}}>
        <tr>
            <td style={{ ...cellCss, ...ThCss }}>Date&Time</td>
            <td style={{ ...cellCss, ...ThCss }}>Order ID</td>
            <td style={{ ...cellCss, ...ThCss }}>Transaction Amt</td>
            <td style={{ ...cellCss, ...ThCss }}>Loan No.</td>
            <td style={{ ...cellCss, ...ThCss }}>Customer Name</td>
            <td style={{ ...cellCss, ...ThCss }}>Phone No.</td>
            <td style={{ ...cellCss, ...ThCss }}>Payment Method</td>
            <td style={{ ...cellCss, ...ThCss }}>Status</td>
            <td style={{ ...cellCss, ...ThCss }}>CBS Receipt No.</td>
            <td style={{ ...cellCss, ...ThCss }}>CBS Status</td>
        </tr>
    </thead>
    const getAmount=()=>{
        return <span>&#8377; {collection_amount}</span>
    }
    const renderList = (data, { index, setShowModal, setFormData }) => {
        collection_amount = collection_amount + data.paid_amount
        return <>
            <tr style={{ border: '1px solid gray' }}>
                <td style={cellCss}>{new Date(data.created_at).toJSON().slice(0, 19)}</td>
                <td style={cellCss}>
                    <div style={{
                        whiteSpace: "nowrap", overflow: "hidden",
                        textOverflow: "ellipsis", maxWidth: 100,
                    }}>
                        {data.order_id}
                    </div>
                </td>
                <td style={{ ...cellCss }}>&#8377; {data.paid_amount}</td>
                <td style={cellCss}>{data.loan}</td>
                <td style={cellCss}>{data.customer}</td>
                <td style={cellCss}>{data.customer_phone}</td>
                <td style={cellCss}>{data.payment_method?.upi?.upi_id}</td>
                <td style={cellCss}>
                    {data.payment_status == "SUCCESS" && <span style={{ color: 'green' }}>{data.payment_status}</span>}
                    {data.payment_status == "CANCEL" && <span style={{ color: 'gray' }}>{data.payment_status}</span>}
                </td>
                <td style={cellCss}>{data.receipt_no}</td>
                <td style={cellCss}>{data.receipt_no.length > 3 ? <span style={{ color: 'green' }}>{data.payment_status}</span> : <></>}</td>
            </tr>
        </>
    }
    const renderSearch = () => <div style={{ ...ThCss, padding: 4 }}>
        From Date:
        <input ref={fromRef} style={{ padding: 6, border: 'none', borderRadius: 4 }} type={"date"} defaultValue={new Date().toJSON().slice(0, 10)} max={new Date().toJSON().slice(0, 10)} />
        <span style={{ paddingLeft: 10, paddingRight: 10 }}>
            To Date:<input ref={toRef} style={{ padding: 6, border: 'none', borderRadius: 4 }} type={"date"} defaultValue={new Date().toJSON().slice(0, 10)} max={new Date().toJSON().slice(0, 10)} /></span>
        <Button onClick={() => {
            fetchData(fromRef.current.value, toRef.current.value)
        }} style={{ ...ThCss, backgroundColor: '#757ce8', color: 'white', marginRight: 5 }}>View</Button>
        <Button onClick={() => {
            download(fromRef.current.value, toRef.current.value)
        }} style={{ ...ThCss, backgroundColor: 'green', color: 'white' }}>
            {progress == 1 && <span>Export</span>}
            {progress != 1 && <CircularProgress size={14} />}
        </Button>
        <span style={{textAlign:"right",width:'100%',display:"inline"}}>
            {/* Total Collection <Chip label={ getAmount()} size={"small"}/> */}
        </span>
    </div>

    const onClickPagination = (page, key) => {

        let url = ""
        if (page["next"] != 'null') {
            url = page["next"].split('?')[0];
        } else if (page["next"] != 'null') {
            url = page["next"].split('?')[0];
        }
        url = url + "?page=" + JSON.stringify(key)
        console.log(url)
        axios.get(url)
            .then(response => {
                setReport(response.data.results);
                setPagination({ count: response.data.count, next: response.data.next, previous: response.data.previous });
                console.log("REPORTS:", response.data)
            });
    }

    const Table = ({ children }) => <div style={{ height: '70vh', overflowY: 'auto' }}><table style={{ width: "100%" }}>
        {renderHead()}
        <tbody>{children}</tbody>
    </table></div>
    return (<>
        <Grid
            container
        >
            {/* <Stack direction="row" spacing={2}>
                <Item>Item 1</Item>
                <Item>Item 2</Item>
                <Item>Item 3</Item>
            </Stack> */}
        </Grid>
        <Box sx={{ flexGrow: 1, mt: 1 }}>
            <Grid container spacing={2}>
                {/* <Grid item xs={12}>
                    <Grid container spacing={1} xs={{ my: 1 }}>
                        <Grid item xs={3}>
                            <ZCard head={"STOCK"} number={10} text={"No data"} />

                        </Grid>
                        <Grid item xs={3}>
                            <ZCard head={"SALE"} number={10} text={"No data  dfdjfd"} />
                        </Grid>

                        <Grid item xs={3}>
                            <ZCard head={"PROFIT"} number={10} text={"No data"} />
                        </Grid>
                        <Grid item xs={3}>
                            <ZCard head={"LOSE"} number={10} text={"No data"} />
                        </Grid>
                    </Grid>
                </Grid> */}
                <Grid item xs={12}>
                    <div style={{ background: '#f2f2f2' }}>
                        <ViewSave
                            dataList={report}
                            pagination={pagination}
                            renderList={renderList}
                            onClickPagination={onClickPagination}
                            title={"Report"}
                            renderHead1={renderHead}
                            renderSearch={renderSearch}
                            element={Table}
                        >
                        </ViewSave>
                    </div>

                </Grid>
            </Grid>
        </Box>
    </>
    );
}