import { all } from 'redux-saga/effects';
import authSaga from "./login";
import * as saga from './saga';

export default function* rootSaga() {
    yield all([
        saga.userSaga(),
        authSaga(),
        saga.dashBoardSaga(),
        saga.chartSaga(),
        saga.companySaga(),
        saga.livecameraSaga(),
        saga.timelapsecameraSaga(),
        saga.projectSaga(),
        saga.groupSaga(),
        saga.moduleSaga(),
        saga.permissionSaga(),
        saga.dbPlanSaga(),
        saga.themeSaga(),
        saga.shopSaga(),
        saga.locationSaga(),
        saga.geoLocationSaga(),
        saga.productSaga(),
        saga.stockSaga(),
        saga.saleSaga(),
    ]);
}
