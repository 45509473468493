const  heading1 = {fontSize:14,color:'#00000'}
const  heading2 = {fontSize:12,color:'#00000'}
const  heading3 = {fontSize:10,color:'#00000'}
const  content = {fontSize:8,color:'#00000'}
export const  titleBar = {
    fontSize:8,color:'#00000'
    
}
export const  body = {
    
}
export const  card = {
    px: 1,
    background: '#D7DBDD',
    borderRadius: 1,
    width: '30%',
    minWidth: 300,
    borderRadius:4,
    marginBottom:2,
    border:'1px solid #BDC3C7',
    position:'relative'
}