import Home from '../ui/home/Home';
import Login from  '../ui/auth/Login'
import InventoryHome from '../ui/inventory/Home';
import SalesHome from '../ui/sales/Home';
import Register from '../ui/shop/Register';
import { Accessibility, AddBusinessRounded, BuildCircleRounded, Castle, Inventory2Rounded, LocalPostOffice, MapRounded, PaymentRounded } from '@mui/icons-material';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import AddToPhotosOutlinedIcon from '@mui/icons-material/AddToPhotosOutlined';
import PointOfSaleOutlinedIcon from '@mui/icons-material/PointOfSaleOutlined';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import LocalAtmOutlinedIcon from '@mui/icons-material/LocalAtmOutlined';
import Button from '@mui/material/Button';
import PostAddIcon from '@mui/icons-material/PostAdd';

import Product from '../ui/product/Product';
import ProductHome from '../ui/product/Home';
import ProductForm from '../ui/product/ProductForm';
import Dashboard from '../ui/dashboard/Home';
import FileUpload from '../ui/upload/Excel';
import Stocks from '../ui/stock/Stocks';
import Sales from '../ui/sales/Home'
import LandingPage from '../ui/LandingPage/LandingPage';
import Location from '../ui/LocationReport/Location';
import Branch from '../ui/LocationReport/Branch';
import Map from '../Map/Map'; 
export const HomePage = Login

export const menuItems = [
  {
    name: "Home",
    exact: true,
    to: "/dashboard/",
    icon: <DashboardOutlinedIcon  sx={{color:'blue'}}/>,
    component: <LandingPage/>,
  },
  {
    name: "Payment Report",
    exact: true,
    to: "/landing/",
    icon: <PaymentRounded  sx={{color:'blue'}}/>,
    component: <Dashboard/>,
  },
  {
    name: "Branch Wise Report",
    exact: true,
    to: "/branch/",
    icon: <Castle  sx={{color:'blue'}}/>,
    component: <Branch />,
  },
  {
    name: "Agent Wise Report",
    exact: true,
    to: "/location/",
    icon: <Accessibility sx={{color:'blue'}}/>,
    component: <Location/>,
  },
  {
    name: "Branch map",
    exact: true,
    to: "/map/",
    icon: <MapRounded sx={{color:'blue'}}/>,
    component: <Map/>,
  },
  // {
  //   name: "Shop",
  //   exact: true,
  //   to: "/shop/",
  //   icon: <AddBusinessRounded />,
  //   component: <Register />
  // },
  // {
  //   name: "Product",
  //   exact: true,
  //   to: "/product/",
  //   icon: <AddToPhotosOutlinedIcon />,
  //   component: <ProductHome/>
  // },
  // {
  //   name: "ProductForm",
  //   exact: true,
  //   to: "/view_product/",
  //   icon: <Inventory2Rounded />,
  //   component: <Product/>,
  //   noMenu: true
  // },
  // {
  //   name: "ProductForm",
  //   exact: true,
  //   to: "/product_add/",
  //   icon: <Inventory2Rounded />,
  //   component: <ProductForm />,
  //   noMenu: true
  // },
  // {
  //   name: "Stock",
  //   exact: true,
  //   to: "/stock/",
  //   icon: <AppRegistrationIcon />,
  //   component: <Stocks/>
  // },
  
  // {
  //   name: "Inventory",
  //   exact: true,
  //   to: "/inventory/",
  //   icon: <PointOfSaleOutlinedIcon />,
  //   component: <InventoryHome/>,
  //   // noMenu:true
  // },
  // {
  //   name: "Sales",
  //   exact: true,
  //   to: "/sales/",
  //   icon: <Inventory2Rounded />,
  //   component: <Sales/>,
  // },
  // {
  //   name: "File Upload",
  //   exact: true,
  //   to: "/file_upload/",
  //   icon: <PostAddIcon/>,
  //   component: <FileUpload/>,
  //   // noMenu:true
  // },
];


