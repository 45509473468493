import { createStore, compose, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import rootReducer from './reducers/index.js';
import rootSaga from "./saga/index";
import { createLogger } from "redux-logger";
const sagaMiddleware = createSagaMiddleware();
const logger = createLogger()
const MiddleWare = [sagaMiddleware, logger]

const store = compose(
    applyMiddleware(...MiddleWare)
)(createStore)(rootReducer);
sagaMiddleware.run(rootSaga);
export default store;

