import React from "react";
import { v4 as uuidv4 } from 'uuid';
import { NavLink, Link } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Tooltip from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';


/**
 * @author
 * @function MenuItem
 **/

const MenuBox = (props) => {
    const { name, subMenus, icon, onClick, to, exact, isExpand, open } = props;
    return (
        <li onClick={onClick} key={uuidv4()}>
            <Link
                key={uuidv4()}
                exact={"true"}
                to={to}
                className={`menu-item`}
                style={{textDecoration:'none',fontSize:12, color:'gray'}}
            >
                <Tooltip title={name} placement="right-start" TransitionComponent={Zoom}>
                    <ListItem disablePadding sx={{ display: 'block' }}>
                        <ListItemButton
                            sx={{
                                minHeight: 20,
                                justifyContent: open ? 'initial' : 'center',
                                px: 2,
                            }}
                        >
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    justifyContent: open ? 'initial' : 'center',
                                }}
                            >
                                {icon}
                            </ListItemIcon>

                            <ListItemText primary={name} sx={{ opacity: open ? 1 : 0, fontSize: 12 }} />

                        </ListItemButton>
                    </ListItem>
                </Tooltip>

            </Link>
            {(subMenus && isExpand) && subMenus.length > 0 ? (
                <>
                    {subMenus.map((menu, index) => (
                        <span key={uuidv4()}>
                            <NavLink to={menu.to}>
                                <ListItem disablePadding sx={{ display: 'block' }}>
                                    <ListItemButton
                                        sx={{
                                            minHeight: 30,
                                            justifyContent: open ? 'initial' : 'center',
                                            px: 2.5,
                                        }}
                                    >
                                        <ListItemIcon
                                            sx={{
                                                minWidth: 0,
                                                fontSize:12,
                                                justifyContent: open ? 'initial' : 'center',
                                            }}
                                        >
                                            {menu.icon}
                                        </ListItemIcon>
                                        <ListItemText primary={menu.name} sx={{ opacity: open ? 1 : 0 }} />
                                    </ListItemButton>
                                </ListItem>
                            </NavLink>
                        </span>
                    ))}
                </>
            ) : null
            }
        </li >
    );
};

export default MenuBox;
