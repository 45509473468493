import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import SkipNextIcon from '@mui/icons-material/SkipNext';

export default function NewCard({image,title,subTitle,onClick}) {
  const theme = useTheme();

  return (
    <div onClick={onClick}>
    <Card sx={{ display: 'flex' ,marginRight:'10px',cursor:'pointer',borderRadius:5,width:350,marginBottom:'10px'}} >
      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
      <CardMedia
        component="img"
        sx={{ width: 170,height: 170 }}
        image={image}
      />
        <CardContent sx={{ flex: '1 0 auto' ,justifyContent:'center',alignItems:'center',display:'flex'}}>
            {title&&(
               <Typography component="div" variant="p" >
               {title}
             </Typography> 
            )}
          
          {subTitle&&(
            <Typography variant="subtitle1" color="text.secondary" component="div">
            {subTitle}
          </Typography>
          )}
          
        </CardContent>
        <Box sx={{ display: 'flex', alignItems: 'center', pl: 1, pb: 1 }}>
          
        </Box>
      </Box>
      
    </Card>
    </div>
  );
}