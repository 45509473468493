import axios from 'axios';

export const ltn_Base_Url='https://vikasmfin.in/api/'

const baseURL = "https://www.vikasmoneyeconnect.com/api/";
// axios.defaults.headers.post['Content-Type'] = "application/json"
axios.defaults.baseURL = baseURL
// axios.defaults.headers.post['Content-Type'] = 'multipart/form-data';
// Get All Todos
let user = JSON.parse(localStorage.getItem("auth"))
console.log("cached user", user)
if (user) {
    console.log("token applied", user)
    axios.defaults.headers.common['Authorization'] = "Token " + user.token
    console.log("header", axios.defaults.headers.common['Authorization'])
}else{
    axios.defaults.headers.common['Authorization'] = null
}
export const get = async (url) => {
    try {
        const todos = await axios.get(url)
        return todos.data;
    } catch (err) {
        console.log("mesasge", err)
        return { error: true, message: err.message }
    }
}

// Create New Todo
export const post = async (url, data) => {

    // axios.defaults.headers.common["content-type"] = 'multipart/form-data'
    console.log("post called", url)
    try {
        const todo = await axios.post(url, data)
        return todo.data;
    } catch (err) {
        return { error: true, message: err.response?.data }
    }
}
export const put = async (url, data) => {
    console.log("put called", url)
    try {
        const todo = await axios.put(url, data);
        return todo.data;
    } catch (err) {
        return { error: true, message: err.response.data }
    }
}
//patch
export const patch = async (url, data) => {
    console.log("patch called", url)
    try {
        const todo = await axios.patch(url, data);
        return todo.data;
    } catch (err) {
        console.log("mesasge", err.response.data)
        return { error: true, message: err.response.data }
    }
}


// Delete existed todo
export const del = async (url, id) => {
    console.log(url, id)
    url = `${url}${id}/`
    try {
        let data = await axios.delete(url)
        console.log("DEL DATA", data);
        return id
    } catch (err) {
        return { error: true, message: err.response.data }
    }
}
