import * as React from 'react';
import { TextField, Button, Snackbar, Alert } from '@mui/material';
import XForm from '../../inputs/XForm';
import Stack from '@mui/system/Stack';
import { Card, Box, Grid, Paper } from '@mui/material';
import XAutoComplete from '../../inputs/XAutoComplate';
import { styled } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import { REQUESTS } from '../../../redux/types';
import XLocation from '../../inputs/XLocation';
import { green } from '@mui/material/colors';
import CircularProgress from '@mui/material/CircularProgress';
import { ArrowBack } from '@mui/icons-material';
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";


const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

export default function ProductForm({ data, onSubmit, children }) {
    let dispatch = useDispatch();
    let params = useParams()
    const [search] = useSearchParams()
    let productState = useSelector(state => state.product)
    const [product, setProduct] = React.useState([])
    const [hsn, setHSN] = React.useState([])
    const [message, setMessage] = React.useState("")
    const [loading, setLoading] = React.useState(false)
    const [formData, setFormData] = React.useState({})


    React.useEffect(() => {
        dispatch({
            type: REQUESTS.PRODUCT.GET.ACTION, payload: 0
        })
        if (productState.data.results) {
            setProduct(productState.data.results)
            var hsn_codes = product.filter((product, index, self) => {
                return index === self.findIndex((t) => (t.hsn_code === product.hsn_code))
            })
            setHSN(hsn_codes)
            setFormData(search.get("data"))
        }

    }, [])
    React.useEffect(() => {
        if (productState.data.results) {
            setProduct(productState.data.results)
            var hsn_codes = product.filter((product, index, self) => {
                return index === self.findIndex((t) => (t.hsn_code === product.hsn_code))
            })
            setHSN(hsn_codes)
            setFormData(JSON.parse(search.get("data")))
        }
    }, [productState])
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setMessage("");
    };

    return (<>
        <Grid
            container
            columns={{ xs: 1, md: 3 }}
            xs={{ margin: 'auto', }}
            alignItems="center"
            justifyContent="center"

        >
            <Grid xs={1}>
                <XForm data={formData} onSubmit={(e, data) => {
                    console.log("DATA:", JSON.stringify(data))
                    setLoading(true)
                    if (data["id"]) {
                        dispatch({
                            type: REQUESTS.PRODUCT.PATCH.ACTION, payload: data, callback: (resp) => {
                                console.log("Data:", resp)
                                setLoading(false)
                                if (resp.error) {
                                    setMessage("Updation failed")
                                } else {
                                    setMessage("Updation Success")
                                }
                            }, no_update: true
                        })
                    } else {
                        dispatch({
                            type: REQUESTS.PRODUCT.POST.ACTION, payload: data, callback: (resp) => {
                                console.log("Data:", resp)
                                setLoading(false)
                                if (resp.error) {
                                    setMessage("Updation failed")
                                } else {
                                    setMessage("Updation Success")
                                }
                            }, no_update: true
                        })
                    }
                }}>
                    <Stack spacing={0.8}>
                        <Item sx={{ dispatch: "flex", justifyContent: 'center' }}>
                            <Link
                                exact={"true"}
                                to={"/product/"}
                                className={`menu-item`}
                            > <ArrowBack sx={{ fontSize: 18 }} /></Link>
                            <Paper variant='success'>
                                PRODUCT DETAILS
                            </Paper>
                            <Snackbar sx={{ display: 'flex', justifyContent: 'center' }} open={message != ""} autoHideDuration={2000} onClose={() => handleClose}>
                                <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                                    This is a success message!
                                </Alert>
                            </Snackbar>
                        </Item>
                        <Item>
                            <XAutoComplete
                                options={product}
                                data_label={"name"} data_value={"id"}
                                label="Product"
                                name="name"
                                style={{ width: "100%" }}
                                onChange={(label, value) => {

                                }}
                            />
                        </Item>
                        <Item>
                            <XAutoComplete
                                options={hsn}
                                data_label={"hsn_code"} data_value={"hsn_code"}
                                label="HSN Code"
                                name="hsn_code"
                                style={{ width: "100%" }}
                                onChange={(label, value) => {

                                }}
                            />
                        </Item>


                        <Item>
                            <TextField
                                required
                                id="percentage"
                                label="Percentage"
                                name="percentage"
                                style={{ width: "100%" }}
                                onChange={() => { console.log("UPDATED***") }}
                            />
                        </Item>
                        <Item>
                            <TextField
                                required
                                id="amount"
                                label="Amount"
                                name="amount"
                                style={{ width: "100%" }}
                            />
                        </Item>

                        <Item>
                            <Button type={"reset"}>Reset</Button>
                            <Button sx={{ color: green[500] }} type={"submit"}>Save</Button>
                            {productState.data.loading && <CircularProgress size={20} />}
                        </Item>
                    </Stack>
                </XForm>
            </Grid>
        </Grid>
    </>
    );
}