import {
  AppBar,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Toolbar,
  Typography,

} from "@mui/material";
import LinearProgress from '@mui/material/LinearProgress';
import React, { useEffect, useState } from "react";
import axios from "axios";
function Map() {
  const [selectedOption2, setSelectedOption2] = useState("");
  const [branchList, setBranchList] = useState([]);
  const [mapResult, setMapResult] = useState("");
  const [loading, setLoading] = useState(false);

  const handleOptionChange2 = (event) => {
    setSelectedOption2(event.target.value);
    const selectedBranch = branchList[event.target.value];
    const centerLocations = selectedBranch.centers.map((center) => {
      return {
        latitude: center.latitude,
        longitude: center.longitude,
        name: center.center_name,
      };
    });
    handleMapView(centerLocations);
  };

  const handleBranch = () => {
    try {
      setLoading(true)
      delete axios.defaults.headers.common['Authorization']
      axios.get("https://vikasmfin.in/api/branch/center/0/").then((response) => {
        console.log("branchList:", JSON.stringify(response.data));
        setBranchList(response.data?.results);
        let centers = []
        let locations = response.data?.results.map((item, index) => {
          item.centers.map((center) => {
            centers.push({
              latitude: center.latitude,
              longitude: center.longitude,
              name: center.center_name,
              color: "red",
            })
          });
          return {
            latitude: item.latitude,
            longitude: item.longitude,
            name: item.branch_name,
            width: 40,
            height: 40
          };
        });
        locations = locations.concat(centers);
        console.log("location from line no.40", locations);
        handleMapView(locations);
      });
    } catch (error) {
      console.log('fetching branch error', error.message);
    }
  };

  const handleMapView = async (locations) => {
    console.log("location data>>>>>>>>>>.", locations);

    try {
      delete axios.defaults.headers.common['Authorization']

      const response = await axios.post("https://pte.zlab.in/api/map/", {
        locations: locations,
        zoom: 9,
      });
      console.log("map response", response.data);
      setMapResult(response.data);
      setTimeout(()=>{ setLoading(false)},1000)
    } catch (error) {
      console.log('fetching map error', error.message);

    }
  };

  useEffect(() => {
    handleBranch();
  }, []);






  return (
    <div>
      <Container maxWidth={false}>
        <div style={{ backgroundColor: "#fff" }}>
          <Grid
            style={{
              paddingBottom: "30px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <TextField
              select
              label="Select Branch"
              value={selectedOption2}
              onChange={handleOptionChange2}
              style={{ padding: "10px", width: "50%" }}
            >
              {branchList?.map((item, index) => (
                <MenuItem value={index}>{item.branch_name}</MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid>
            {loading && <LinearProgress />}
            {!loading && <div style={{ height: "50%", width: "100%" }}>
              <div dangerouslySetInnerHTML={{ __html: mapResult }}></div>
            </div>}
          </Grid>
        </div>
      </Container>

      <Grid>
        <div></div>
      </Grid>
    </div>
  );
}

export default Map;
