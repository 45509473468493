import * as React from 'react';
import { TextField, Button, Snackbar, Alert, autocompleteClasses } from '@mui/material';
import XForm from '../../inputs/XForm';
import Stack from '@mui/system/Stack';
import { Card, Grid, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useDispatch} from 'react-redux';
import { REQUESTS } from '../../../redux/types';
import XGeoMove from '../../inputs/XGeoMove';
import AddLocationIcon from '@mui/icons-material/AddLocation';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import LoginIcon from '@mui/icons-material/Login';
import { Link } from "react-router-dom";


const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'left',
    color: theme.palette.text.secondary,

}));

export default function Home() {
    let dispatch = useDispatch();
    let [company, setCompany] = React.useState([])
    const [message, setMessage] = React.useState("")

    const init = (latitude, longitude) => {
        dispatch({
            type: REQUESTS.COMPANY.SEARCH.ACTION,
            payload: `/nearest/${latitude}/${longitude}/`,
            callback: (data) => {
                console.log("COMAPNY...", data)
                setCompany(data)
            }
        })
    }
    const getLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                init(position.coords.latitude, position.coords.longitude)
            });
        }else{
            init(0, 0)
        }
    }
    React.useEffect(() => {
        getLocation()
    }, [])
    React.useEffect(() => {
        if (company && company.length == 0) {
            getLocation()
        }
    }, [company])

    if (company.error){
        return(<><Item sx={{background:"red",color:"white"}}>{company.message}</Item></>) 
    }

    return (<>
        
        <Grid
            container
            columns={{ xs: 1, md: 3 }}
            xs={{ margin: 'auto', }}
            alignItems="center"
            justifyContent="center"

        >
            <Grid xs={1}>
                <Stack spacing={0.6} sx={{ mb: 0.5 }}>
                    <Card sx={{ display: 'flex', alignItems: "center" }}>
                        <div style={{ display: 'flex', marginLeft: 10, fontSize: 18, fontFamily: 'sans-serif', flexDirection: 'column', width: "100%" }}>
                            NEAREST SKS OUTLETS
                        </div>
                        <div style={{ marginRight: 10 }}>
                            <XGeoMove onChange={(latitude, longitude, move) => {
                                init(latitude, longitude)
                            }} />
                        </div>
                        <div style={{ marginRight: 10 }}>
                            <Link
                                exact={"true"}
                                to={"/login"}
                                className={`menu-item`}
                            > <LoginIcon sx={{ fontSize: 18 }} /></Link>
                        </div>
                    </Card>
                </Stack>
                <Stack
                    sx={{ overflowY: 'auto', maxHeight: '88vh' }}
                    spacing={0.6}
                >
                    {company && company.map((c) => {
                        let map = "https://www.google.com/maps/@" + c.latitude + "," + c.longitude + ",15z?entry=ttu"
                        return (<Item key={c.id}>
                            <div>{c.name}</div>
                            <div>{c.place}</div>
                            <div>{c.distance}</div>
                            <a href={map}><AddLocationIcon sx={{ fontSize: 25 }} /></a></Item>)
                    })}
                </Stack>
            </Grid>

        </Grid>
    </>
    );
}