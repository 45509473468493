import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { post } from '../actions/api';
import { LOGIN_SUCCESS, LOGIN_REQUEST, LOGIN_FAILED, LOGOUT_REQUEST, LOGOUT } from '../types';
import axios from 'axios';


function* auth({ type, payload, navigate }) {
    console.log(payload);
    //  const userDetails = JSON.stringify(payload)
    // console.log( "login requested user " + userDetails);
    try {
        const data = yield call(post, "token/", payload);
        if (data.error) {
            // console.log(data.message.non_field_errors[0]);
            yield put({ "type": LOGIN_FAILED, error: true, message: data.message.non_field_errors[0] });
        } else {
            console.log("saga -auth", data)
            axios.defaults.headers.common['Authorization'] = "Token " + data.token
            yield put({ "type": LOGIN_SUCCESS, error: false, "data": data, message: "login successfully" });
            navigate("/dashboard");
        }
    } catch (error) {
        console.log(error)
        // yield put({"type": LOGIN_FAILED, "error": {"message": "Invalid Credentials"}});
        yield put({ "type": LOGIN_FAILED, message: "Invalid Credentials" });
        navigate("/");
    }
}

function* logout({ type, navigate }) {
    try {
        console.log("saga logout");
        localStorage.removeItem("auth");
        yield put({ "type": LOGOUT });
        navigate("/");
    } catch (error) {
        console.log(error)
        yield put({ "type": LOGIN_FAILED, "error": { "message": "Invalid Credentials" } });
    }
}

function* authSaga() {
    yield takeEvery(LOGIN_REQUEST, auth);
    yield takeLatest(LOGOUT_REQUEST, logout);
}

export default authSaga