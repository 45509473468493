import * as React from 'react';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import MenuBox from '../menus/MenuItem';
import { v4 as uuidv4 } from 'uuid';
export default function XSideBar({menuItems}) {

  return (
    <>
      {menuItems.map((menuItem, index) => {
        if (menuItem.noMenu) { return null }
        return (
          <>
            <List>
              <MenuBox
                key={uuidv4()}
                name={menuItem.name}
                exact={"true"}
                to={menuItem.to}
                subMenus={menuItem.subMenus || []}
                icon={menuItem.icon}
                isExpand={menuItem.isExpand}
                onClick={(e) => {
                  menuItem.isExpand = menuItem.isExpand ? false : true
                }}
              />
            </List>
            <Divider />
          </>)
      })}
    </>
  );
}