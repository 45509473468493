import React, { useEffect, useState } from 'react'
import { Box, CircularProgress, Container, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, createTheme } from '@mui/material'
import { ThemeProvider } from '@emotion/react'
import ZCard from '../../ZCard/ZCard'
import { Assessment, Ballot, CorporateFare, Countertops } from '@mui/icons-material'
import { LocationStyles } from './Styles'
import ViewSave from '../common/ViewSave'
import axios from 'axios'
import { ltn_Base_Url } from '../../../redux/actions/api'

const Location = () => {
    const [result, setResult] = useState([])
    const [branch, setBranch] = useState([])
    const [loading, setLoading] = useState(false)
    const total = {}
    const defaultTheme = createTheme({
        typography: {
            fontFamily: ['Poppins', 'sans-serif'].join(','),
        },
    })
    const getBranch = () => {
        setLoading(loading)
        try {
            delete axios.defaults.headers.common['Authorization']
            const instance = axios.create({
                baseURL: 'https://vikasmfin.in/api/',
                headers: {
                    'Accept': 'application/json'
                }
            });
            instance.get(`branch/0/`).then((response) => {
                console.log('Branch', response);
                setBranch(response.data);
                setLoading(false)

            })
        } catch (error) {
            console.log(error.message)
            setLoading(false)

        }

    }

    useEffect(() => {
        getBranch()
        renderLocatio_data("agent_name")
    }, [])
    const renderLocatio_data = (sort, loading = true) => {
        setLoading(loading)
        try {
            delete axios.defaults.headers.common['Authorization']
            const instance = axios.create({
                baseURL: 'https://vikasmfin.in/api/',
                headers: {
                    'Accept': 'application/json'
                }
            });
            instance.get(`location/report/0/${sort}/`).then((response) => {
                console.log('Location response', response);
                setResult(response.data);
                setLoading(false)

            })
        } catch (error) {
            console.log(error.message)
            setLoading(false)

        }
    }

    const ThCss = {
        fontWeight: 700,
        fontSize: 10,
        background: '#E6E9EE',
        width: '10%',
        paddingLeft: 4, paddingRight: 4
    }
    const cellCss = {
        borderBottom: '0.5px solid #d9dcde',
        fontSize: 12,
        paddingLeft: 4,
        background: '#F4F6F9'

    }
    const renderList = (data, { index, setShowModal, setFormData }) => {
        return (
            <TableRow sx={{ '&:last-child': { borderBottom: '2px solid #ccc' } }}>
                <TableCell align="left" sx={{ bgcolor: '#eeeeee', width: '3%' }}>{index + 1}</TableCell>
                <TableCell align="left" sx={{ ...cellCss }}>{data.agent_name}</TableCell>
                <TableCell align="right" sx={{ ...cellCss }}>{data.total}</TableCell>
                <TableCell align="right" sx={{ ...cellCss }}>{data.updated}</TableCell>
                <TableCell align="right" sx={{ ...cellCss }}>{data.not_updated}</TableCell>
            </TableRow>
        )
    }
    const renderHead = () =>
        <TableRow sx={{ '&:last-child': { borderBottom: '1px solid #ccc', position: 'sticky' } }}>
            <TableCell sx={{ ...ThCss, width: '3%', minWidth: 80, textAlign: 'left' }}>SL No.</TableCell>
            <TableCell align="left" sx={{ ...ThCss }} onClick={() => { (renderLocatio_data("-agent_name", false)) }}>Agent Name</TableCell>
            <TableCell align="right" sx={{ ...ThCss }} onClick={() => { (renderLocatio_data("-total", false)) }}>Total count</TableCell>
            <TableCell align="right" sx={{ ...ThCss }} onClick={() => { (renderLocatio_data("-updated", false)) }}>Updated count</TableCell>
            <TableCell align="right" sx={{ ...ThCss }} onClick={() => { (renderLocatio_data("-not_updated", false)) }}>Pending count</TableCell>
        </TableRow>

    const createParent = ({ children }) => {
        return <div style={{ height: '82vh', overflowY: 'auto' }}>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                    <TableHead style={{ position: "sticky", top: 0 }}>
                        {renderHead()}
                    </TableHead>
                    <TableBody> {children}</TableBody>
                </Table>
            </TableContainer>
        </div>
    }
    const renderSerach = () => <></>
    return (
        <ThemeProvider theme={defaultTheme}>
            <Container>
                {loading ? (
                    <Box sx={{
                        alignSelf: 'center', display: 'flex', flexDirection: 'row',
                        justifyContent: 'center', alignItems: 'center', marginTop: 50
                    }}>
                        <CircularProgress color="success" />
                        <Typography sx={{ marginLeft: '10px' }}>Loading please wait</Typography>
                    </Box>
                ) : (
                    <>
                        <Grid sx={{
                            display: 'flex',
                            flexDirection: 'row ', justifyContent: 'space-around', margin: '30px'
                        }} >
                            <ZCard Title={'TOTAL COUNT'} subTitle={result?.total}>
                                <Assessment sx={{ color: 'Blue' }} />
                            </ZCard>
                            <ZCard Title={'UPDATED'} subTitle={result?.updated}>
                                <Ballot sx={{ color: 'green' }} />
                            </ZCard>
                            <ZCard Title={'PENDING'} subTitle={result?.not_updated}>
                                <CorporateFare sx={{ color: 'red' }} />
                            </ZCard>
                        </Grid>

                        <ViewSave dataList={result?.data}
                            renderList={renderList}
                            element={createParent}
                            title={"Report"}
                            renderSearch={renderSerach}
                        />
                    </>
                )}
            </Container >
        </ThemeProvider>

    )
}

export default Location