import { combineReducers } from 'redux';
import auth from './login';
import * as type from '../types';
console.log("reducing...", type.REQUESTS.COMPANY.reducer)
const rootReducer = combineReducers({
    auth: auth,
    users: type.REQUESTS.USER.reducer,
    dashboard: type.REQUESTS.DASHBOARD.reducer,
    chart: type.REQUESTS.CHART.reducer,
    company: type.REQUESTS.COMPANY.reducer,
    livecamera: type.REQUESTS.LIVECAM.reducer,
    timelapsecamera: type.REQUESTS.TIMELAPSE.reducer,
    project: type.REQUESTS.PROJECT.reducer,
    group: type.REQUESTS.GROUP.reducer,
    module: type.REQUESTS.MODULE.reducer,
    permission: type.REQUESTS.PERMISSION.reducer,
    text: type.REQUESTS.TEXT.reducer,
    theme: type.REQUESTS.THEME.reducer,
    center: type.REQUESTS.CENTER.reducer,
    location: type.REQUESTS.LOCATION.reducer,
    geolocation: type.REQUESTS.GEOLOCATION.reducer,
    product: type.REQUESTS.PRODUCT.reducer,
    stock: type.REQUESTS.STOCK.reducer,
    sale: type.REQUESTS.SALE.reducer,

});
export default rootReducer;