import { Stack } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import XMenu from '../basic/XMenu';
import { useDispatch, useSelector } from 'react-redux';
import { REQUESTS, LOGOUT_REQUEST } from '../../redux/types';
import { useNavigate } from "react-router-dom";
import React from 'react';
const ZToolbar = ({ handleDrawerOpen,open }, prop) => {
    let navigate = useNavigate()
    let dispatch = useDispatch()
    React.useEffect(()=>{},[open])
    return <div {...prop}>
        <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
            sx={{ px: 1, py: 0.8 }}
        >
            <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                sx={{
                    marginLeft: 5,
                    p: 0,
                    ...(open && { display: 'none' }),
                }}
            >
                <MenuIcon />
            </IconButton>
            <Box sx={{ flexGrow: 1 }}>
                <Typography sxvariant="h6" noWrap component="div">
                    VPay Report
                </Typography>
            </Box>
            <Box sx={{ flexGrow: 0, marginRight: 5 }}>
                <XMenu list={["Profile", "Logout"]} onClick={(e, action) => {
                    if (action == "Logout") {
                        dispatch({
                            type: LOGOUT_REQUEST,
                            navigate: navigate
                        })
                    }
                }} />
            </Box>
        </Stack>
    </div>
}
export default ZToolbar