import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Grid, Snackbar, Alert, Box, Divider, Button, CircularProgress,Chip } from '@mui/material';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import XForm from '../../inputs/XForm';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import EditNoteRoundedIcon from '@mui/icons-material/EditNoteRounded';
import Stack from '@mui/material/Stack';
import ControlPointRoundedIcon from '@mui/icons-material/ControlPointRounded';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLess from '@mui/icons-material/ExpandLess';
import Typography from '@mui/material/Typography';
import { TextField } from '@mui/material';
import Pagination from '@mui/material/Pagination';


export default function ViewSave({
    title,
    dataList, keys, children, onSubmit,
    renderView, renderList, renderHead, onDelete, element, elementCss,
    onEdit, renderSearch, renderPagination, onSearch, style, height, pagination,
    onClickPagination, hideCols, hideMore, showKeys, css
}) {

    let ref = React.createRef()
    let refReset = React.createRef()
    const [fullScreen, setFullScreen] = React.useState(true)
    const [showModal, setShowModal] = React.useState(false)
    const [loading, setLoading] = React.useState(false)
    const [formData, setFormData] = React.useState(false)
    const [message, setMessage] = React.useState("This is the message")
    const [search, setSearch] = React.useState("")
    const [showDetails, setshowDetails] = React.useState(-1)
    elementCss = elementCss ? elementCss : { sx: { width: '100%', overflowY: 'auto', height: height ? height : '70vh' } }
    hideCols = hideCols ? hideCols : []
    hideMore = hideMore ? hideMore : []
    css = css ? css : { rowColor: '#e9f0f4' }
    onEdit = onEdit ? onEdit : (data, setShowModal, setLoading) => {
        setFormData(data)
        setShowModal(true)
    }
    onDelete = onDelete ? onDelete : (data, setLoading) => { }
    renderList = renderList ? renderList : (data, { index }) => {
        const labelId = `checkbox-list-secondary-label-${data}`;
        return (<>
            <div style={{
                background: css.rowColor,
                borderRadius: css.radius ? css.radius : 4
            }}>
                <Stack
                    direction="row"
                    spacing={2}
                >
                    <ListItemButton sx={{ maxWidth: 40, p: 1 }}>
                        <ListItemAvatar>
                            <Avatar
                                alt={`Avatar n°${data["icon"] + 1}`}
                                src={`/static/images/avatar/${data["icon"] + 1}.jpg`}
                            />
                        </ListItemAvatar>
                        {/* <ListItemText id={labelId} primary={""} sx={{ maxWidth: 100, minWidth: 60 }} /> */}
                    </ListItemButton>

                    {Object.keys(data).map((key, i) => {
                        if (showKeys) {
                            return <div>{key}</div>
                        }
                        if (hideCols.indexOf(key) > -1) return
                        return <Box display="flex" alignItems={"center"} key={i} sx={{ px: 1, width: '100%' }}>
                            <ListItemText primary={data[key]} />
                        </Box>
                    }
                    )}
                    <Box display="flex" alignItems={"center"} sx={{ pr: 1 }}>
                        <EditNoteRoundedIcon variant={"primary"} sx={{ fontSize: 16, cursor: 'pointer', color: '#418ae9' }} onClick={() => { onEdit(data, setShowModal, setLoading) }} />
                        <CancelRoundedIcon variant={"error"} title={"Delete"} sx={{ color: '#e95541', fontSize: 16, cursor: 'pointer' }} onClick={() => { onDelete(data, setLoading) }} />
                    </Box>
                </Stack>
                <div style={{ textAlign: 'right' }}>
                    {showDetails != index && <ExpandMoreIcon onClick={() => { setshowDetails(index) }} />}
                    {showDetails == index && <ExpandLess onClick={() => { setshowDetails(-1) }} />}
                </div>
                <div style={{ paddingLeft: 8, paddingRight: 8 }}>
                    {index == showDetails &&
                        <div style={{ background: css.detailColor ? css.detailColor : '#ebebeb', borderRadius: 4 }}>
                            {Object.keys(data).map((key, i) => {
                                if (!data[key]) return
                                if (showKeys) {
                                    return <div>{key}</div>
                                }
                                if (hideMore.indexOf(key) > -1) return
                                return <Typography>{data[key]}</Typography>
                            }
                            )}
                        </div>
                    }

                </div>
            </div>
            <Divider variant="inset" component="li" />
        </>
        )
    }
    onSearch = onSearch ? onSearch : (value) => { }
    renderHead = renderHead ? renderHead : (value) => { }

    renderSearch = renderSearch ? renderSearch : () => {
        return <TextField sx={{ border: 'none', borderRadius: 2 }} value={search} onChange={(e) => { setSearch(e.target.value); onSearch(e.target.value,) }} size="small" />
    }
    const parentEle = () => {
        return element ? element : List
    }
    renderView = renderView ? renderView : (dataList, renderList) => {
        return React.createElement(
            parentEle(),
            elementCss,
            <>
                {renderHead()}
                {dataList && dataList.map((data, i) => {
                    return renderList(data, { index: i, setFormData: setFormData, setShowModal: setShowModal, setLoading: setLoading })
                })}
            </>
        );
    }
    const handleChange = (event, number) => {
        onClickPagination(pagination, number)
    }

    renderPagination = renderPagination ? renderPagination : (page) => {
        return <>
            {pagination && (<Stack
                direction="row"
                spacing={2}
                alignItems={"center"}
            >
                <Pagination sx={{ my: 1 }} count={parseInt(pagination.count / 20)} onChange={handleChange} />
                <Chip size={"small"} label={"Total:" + pagination.count}  style={{ fontSize: 10 }}></Chip>
            </Stack>
            )}
        </>
    }

    React.useEffect(() => { }, [dataList])
    const onSubmitIn = (e, data) => {
        if (onSubmit) {
            onSubmit(e, data, setLoading, setMessage, (data1) => { setFormData(data1) })
        }
    }
    return (
        <>
            <Dialog
                open={showModal}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title" sx={{ background: '#757ce8', p: 0.5, px: 1, color: 'white' }}>
                    <Typography variant='caption'>{title}</Typography>
                    <Snackbar sx={{ display: 'flex', justifyContent: 'center' }} open={message != ""} autoHideDuration={2000} onClose={() => { setMessage("") }}>
                        <Alert onClose={() => { setMessage("") }} severity="success" sx={{ width: '100%' }}>
                            {message}
                        </Alert>
                    </Snackbar>
                </DialogTitle>
                <DialogContent>
                    <div style={{ zIndex: 99, margin: 'auto', position: 'absolute', width: 100, height: 100, left: 0, right: 0, bottom: 0, top: 0 }}>
                        {loading && <CircularProgress />}
                    </div>
                    <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
                        <XForm data={formData} onSubmit={onSubmitIn}>
                            {children}
                            <input type={"reset"} ref={refReset} style={{ display: 'none' }} />
                            <input type={"submit"} ref={ref} style={{ display: 'none' }} />
                        </XForm>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={() => { refReset.current.click(); setShowModal(false) }}>
                        Cancel
                    </Button>
                    {loading && <CircularProgress sx={{ fontSize: 10 }} />}
                    {!loading && <Button onClick={() => { ref.current.click() }} autoFocus>
                        Save
                    </Button>}
                </DialogActions>
            </Dialog>
            <div style={style}>
                <Box display="flex" sx={{ width: '100%', minWidth: 360, background: '#757ce8', p: 0.5, px: 2, mb: 0 }} justifyContent={'space-between'} alignItems={"center"}>
                    <Typography variant='caption' sx={{ color: 'white' }}>{title}</Typography>
                    {children && <Typography variant='caption' sx={{ pt: 0.5 }}>
                        <ControlPointRoundedIcon sx={{ color: 'white', cursor: 'pointer', fontSize: 16 }} onClick={() => { setFormData({}); setShowModal(true); }} />
                    </Typography>}
                </Box>
                <Box display="flex" sx={{ background: '#dedff0', px: 2, py: 1, width: '100%', minWidth: 360, mb: 0 }}>
                    {renderSearch()}
                </Box>
                {renderView(dataList, renderList)}
                {renderPagination(pagination)}
            </div>
        </>
    )
}