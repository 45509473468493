import * as type from '../types';
import { cachedData, updateCacheData } from './util'

export default function common(MODULE_NAME = "", state = {}, action) {
    switch (action.type) {
        case type.REQUESTS[MODULE_NAME]["GET"]["ACTION"] || type.REQUESTS[MODULE_NAME]["POST"]["ACTION"] ||
            type.REQUESTS[MODULE_NAME]["PUT"]["ACTION"] || type.REQUESTS[MODULE_NAME]["PATCH"]["ACTION"] ||
            type.REQUESTS[MODULE_NAME]["DELETE"]["ACTION"]:
            return {
                ...state,
                loading: true,
            };
        case MODULE_NAME + "_SUCCESS":
            let prev = { ...state.data }
            return {
                ...state,
                data: updateCacheData(MODULE_NAME, prev, action.data, action.action),
                loading: false,
                success: true,
                message: "Data Successfully Saved"

            };

        case MODULE_NAME + "_FAILED":
            return {
                ...state,
                loading: false,
                message: "Failed This Action ",
                success: false
            };
        default:
            return state;
    }

}