export const LandinStyles={
    container:{
        // backgroundColor:'#fff',
        display:'flex',
        width:'100%',
        height:'20%',
        justifyContent: 'center',
        alignitems: 'center',
       
    }
}