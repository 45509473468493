//login api
import { cachedData } from './reducers/util'
import common from './reducers/common'
export const FILL = "FILL"
export const APPEND = "APPEND"
export const REPLACE = "REPLACE"
export const REMOVE = "REMOVE"
export const DO_NOTHING = "DO_NOTHING"
export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILED = "LOGIN_FAILED";
export const LOGOUT = "LOGOUT";
export const LOGOUT_REQUEST = "LOGOUT_REQUEST";
export const LOGGED_IN = "LOGGED_IN";
export const AUTH_DATA = "AUTH_DATA";
export const POST_API_REQUEST = "POST_API_REQUEST";
export const GET_API_REQUEST = "GET_API_REQUEST";
export const PUT_API_REQUEST = "PUT_API_REQUEST";
export const PATCH_API_REQUEST = "PATCH_API_REQUEST";
export const DELETE_API_REQUEST = "DELETE_API_REQUEST";
export const SEARCH_API_REQUEST = "SEARCH_API_REQUEST";
export const API_URL = "API_URL";
export const API_SUCCESS = "API_SUCCESS";
export const API_FAILED = "API_FAILED";
export const STORENAME = "STORENAME";
export const COMPANY = "COMPANY"
export const GROUP = "GROUP"
export const MODULE = "MODULE"
export const DASHBOARD = "DASHBOARD"
export const USER = "USER"
export const CHART = "CHART"

export const LIVECAM = "LIVECAM"
export const TIMELAPSE = "TIMELAPSE"
export const PROJECT = "PROJECT"
export const PERMISSION = "PERMISSION"
export const DB_PLAN = "DB_PLAN"
export const THEME = "THEME"
export const ACTION = "ACTION"
export const GET = "GET"
export const POST = "POST"
export const PUT = "PUT"
export const PATCH = "PATCH"
export const DELETE = "DELETE"

export const REQUESTS = {
    "COMPANY": {
        "GET": { "URL": "company", "ACTION": "COMPANY_GET" },
        "POST": { "URL": "company", "ACTION": "COMPANY_POST" },
        "PUT": { "URL": "company", "ACTION": "COMPANY_PUT" },
        "PATCH": { "URL": "company", "ACTION": "COMPANY_PATCH" },
        "DELETE": { "URL": "company", "ACTION": "COMPANY_DELETE" },
        "SEARCH": { "ACTION": "COMPANY_SEARCH" },
        "STATE": {
            data: cachedData("COMPANY", "object"),
            loading: false,
            message: "initail",
            success: false,
        },
        reducer: function (state = REQUESTS.COMPANY.STATE, action) {
            return common("COMPANY", state, action)
        }
    },
    "GROUP": {
        "GET": { "URL": "group", "ACTION": "GROUP_GET" },
        "POST": { "URL": "group", "ACTION": "GROUP_POST" },
        "PUT": { "URL": "group", "ACTION": "GROUP_PUT" },
        "PATCH": { "URL": "group", "ACTION": "GROUP_PATCH" },
        "DELETE": { "URL": "group", "ACTION": "GROUP_DELETE" },
        "SEARCH": { "ACTION": "GROUP_SEARCH" },
        "STATE": {
            data: cachedData("GROUP", "object"),
            loading: false,
            message: "initail",
            success: false,
        },
        reducer: function (state = REQUESTS.COMPANY.STATE, action) {
            return common("GROUP", state, action)
        }
    },
    "MODULE": {
        "GET": { "URL": "module", "ACTION": "MODULE_GET" },
        "POST": { "URL": "module", "ACTION": "MODULE_POST" },
        "PUT": { "URL": "module", "ACTION": "MODULE_PUT" },
        "PATCH": { "URL": "module", "ACTION": "MODULE_PATCH" },
        "DELETE": { "URL": "module", "ACTION": "MODULE_DELETE" },
        "SEARCH": { "ACTION": "MODULE_SEARCH" },
        "STATE": {
            data: cachedData("MODULE", "object"),
            loading: false,
            message: "initail",
            success: false,
        },
        reducer: function (state = REQUESTS.COMPANY.STATE, action) {
            return common("MODULE", state, action)
        }
    },
    "DASHBOARD": {
        "GET": { "URL": "dashboard", "ACTION": "DASHBOARD_GET" },
        "POST": { "URL": "dashboard", "ACTION": "DASHBOARD_POST" },
        "PUT": { "URL": "dashboard", "ACTION": "DASHBOARD_PUT" },
        "PATCH": { "URL": "dashboard", "ACTION": "DASHBOARD_PATCH" },
        "DELETE": { "URL": "dashboard", "ACTION": "DASHBOARD_DELETE" },
        "SEARCH": { "ACTION": "DASHBOARD_SEARCH" },
        "STATE": {
            data: cachedData("DASHBOARD", "object"),
            loading: false,
            message: "initail",
            success: false,
        },
        reducer: function (state = REQUESTS.COMPANY.STATE, action) {
            return common("DASHBOARD", state, action)
        }
    },
    "USER": {
        "GET": { "URL": "user", "ACTION": "USER_GET" },
        "POST": { "URL": "user", "ACTION": "USER_POST" },
        "PUT": { "URL": "user", "ACTION": "USER_PUT" },
        "PATCH": { "URL": "user", "ACTION": "USER_PATCH" },
        "DELETE": { "URL": "user", "ACTION": "USER_DELETE" },
        "SEARCH": { "ACTION": "USER_SEARCH" },
        "STATE": {
            data: cachedData("USER", "object"),
            loading: false,
            message: "initail",
            success: false,
        },
        reducer: function (state = REQUESTS.COMPANY.STATE, action) {
            return common("USER", state, action)
        }
    },
    "CHART": {
        "GET": { "URL": "chart", "ACTION": "CHART_GET" },
        "POST": { "URL": "chart", "ACTION": "CHART_POST" },
        "PUT": { "URL": "chart", "ACTION": "CHART_PUT" },
        "PATCH": { "URL": "chart", "ACTION": "CHART_PATCH" },
        "DELETE": { "URL": "chart", "ACTION": "CHART_DELETE" },
        "SEARCH": { "ACTION": "CHART_SEARCH" },
        "STATE": {
            data: cachedData("CHART", "object"),
            loading: false,
            message: "initail",
            success: false,
        },
        reducer: function (state = REQUESTS.COMPANY.STATE, action) {
            return common("CHART", state, action)
        }
    },
    "LIVECAM": {
        "GET": { "URL": "livecam", "ACTION": "LIVECAM_GET" },
        "POST": { "URL": "livecam", "ACTION": "LIVECAM_POST" },
        "PUT": { "URL": "livecam", "ACTION": "LIVECAM_PUT" },
        "PATCH": { "URL": "livecam", "ACTION": "LIVECAM_PATCH" },
        "DELETE": { "URL": "livecam", "ACTION": "LIVECAM_DELETE" },
        "SEARCH": { "ACTION": "LIVECAM_SEARCH" },
        "STATE": {
            data: cachedData("LIVECAM", "object"),
            loading: false,
            message: "initail",
            success: false,
        },
        reducer: function (state = REQUESTS.COMPANY.STATE, action) {
            return common("LIVECAM", state, action)
        }
    },

    "TIMELAPSE": {
        "GET": { "URL": "timelpase", "ACTION": "TIMELAPSE_GET" },
        "POST": { "URL": "timelpase", "ACTION": "TIMELAPSE_POST" },
        "PUT": { "URL": "timelpase", "ACTION": "TIMELAPSE_PUT" },
        "PATCH": { "URL": "timelpase", "ACTION": "TIMELAPSE_PATCH" },
        "DELETE": { "URL": "timelpase", "ACTION": "TIMELAPSE_DELETE" },
        "SEARCH": { "ACTION": "TIMELAPSE_SEARCH" },
        "STATE": {
            data: cachedData("TIMELAPSE", "object"),
            loading: false,
            message: "initail",
            success: false,
        },
        reducer: function (state = REQUESTS.COMPANY.STATE, action) {
            return common("TIMELAPSE", state, action)
        }
    },

    "PROJECT": {
        "GET": { "URL": "project", "ACTION": "PROJECT_GET" },
        "POST": { "URL": "project", "ACTION": "PROJECT_POST" },
        "PUT": { "URL": "project", "ACTION": "PROJECT_PUT" },
        "PATCH": { "URL": "project", "ACTION": "PROJECT_PATCH" },
        "DELETE": { "URL": "project", "ACTION": "PROJECT_DELETE" },
        "SEARCH": { "ACTION": "PROJECT_SEARCH" },
        "STATE": {
            data: cachedData("PROJECT", "object"),
            loading: false,
            message: "initail",
            success: false,
        },
        reducer: function (state = REQUESTS.COMPANY.STATE, action) {
            return common("PROJECT", state, action)
        }
    },

    "PERMISSION": {
        "GET": { "URL": "permission", "ACTION": "PERMISSION_GET" },
        "POST": { "URL": "permission", "ACTION": "PERMISSION_POST" },
        "PUT": { "URL": "permission", "ACTION": "PERMISSION_PUT" },
        "PATCH": { "URL": "permission", "ACTION": "PERMISSION_PATCH" },
        "DELETE": { "URL": "permission", "ACTION": "PERMISSION_DELETE" },
        "SEARCH": { "ACTION": "PERMISSION_SEARCH" },
        "STATE": {
            data: cachedData("PERMISSION", "object"),
            loading: false,
            message: "initail",
            success: false,
        },
        reducer: function (state = REQUESTS.COMPANY.STATE, action) {
            return common("PERMISSION", state, action)
        }
    },

    "THEME": {
        "GET": { "URL": "theme", "ACTION": "THEME_GET" },
        "POST": { "URL": "theme", "ACTION": "THEME_POST" },
        "PUT": { "URL": "theme", "ACTION": "THEME_PUT" },
        "PATCH": { "URL": "theme", "ACTION": "THEME_PATCH" },
        "DELETE": { "URL": "theme", "ACTION": "THEME_DELETE" },
        "SEARCH": { "ACTION": "THEME_SEARCH" },
        "STATE": {
            data: cachedData("THEME", "object"),
            loading: false,
            message: "initail",
            success: false,
        },
        reducer: function (state = REQUESTS.COMPANY.STATE, action) {
            return common("THEME", state, action)
        }
    },

    "DB_PLAN": {
        "GET": { "URL": "db_plan", "ACTION": "DB_PLAN_GET" },
        "POST": { "URL": "db_plan", "ACTION": "DB_PLAN_POST" },
        "PUT": { "URL": "db_plan", "ACTION": "DB_PLAN_PUT" },
        "PATCH": { "URL": "db_plan", "ACTION": "DB_PLAN_PATCH" },
        "DELETE": { "URL": "db_plan", "ACTION": "DB_PLAN_DELETE" },
        "SEARCH": { "ACTION": "DB_PLAN_SEARCH" },
        "STATE": {
            data: cachedData("DB_PLAN", "object"),
            loading: false,
            message: "initail",
            success: false,
        },
        reducer: function (state = REQUESTS.COMPANY.STATE, action) {
            return common("DB_PLAN", state, action)
        }
    },

    "MENU": {
        "GET": { "URL": "menu", "ACTION": "MENU_GET" },
        "POST": { "URL": "menu", "ACTION": "MENU_POST" },
        "PUT": { "URL": "menu", "ACTION": "MENU_PUT" },
        "PATCH": { "URL": "menu", "ACTION": "MENU_PATCH" },
        "DELETE": { "URL": "menu", "ACTION": "MENU_DELETE" },
        "SEARCH": { "ACTION": "MENU_SEARCH" },
        "STATE": {
            data: cachedData("MENU", "object"),
            loading: false,
            message: "initail",
            success: false,
        },
        reducer: function (state = REQUESTS.COMPANY.STATE, action) {
            return common("MENU", state, action)
        }
    },
    "TEXT": {
        "GET": { "URL": "text", "ACTION": "TEXT_GET" },
        "POST": { "URL": "text", "ACTION": "TEXT_POST" },
        "PUT": { "URL": "text", "ACTION": "TEXT_PUT" },
        "PATCH": { "URL": "text", "ACTION": "TEXT_PATCH" },
        "DELETE": { "URL": "text", "ACTION": "TEXT_DELETE" },
        "SEARCH": { "ACTION": "TEXT_SEARCH" },
        "STATE": {
            data: cachedData("TEXT", "object"),
            loading: false,
            message: "initail",
            success: false,
        },
        reducer: function (state = REQUESTS.COMPANY.STATE, action) {
            return common("TEXT", state, action)
        }
    },
    "CENTER": {
        "GET": { "URL": "center", "ACTION": "CENTER_GET" },
        "POST": { "URL": "center", "ACTION": "CENTER_POST" },
        "PUT": { "URL": "center", "ACTION": "CENTER_PUT" },
        "PATCH": { "URL": "center", "ACTION": "CENTER_PATCH" },
        "DELETE": { "URL": "center", "ACTION": "CENTER_DELETE" },
        "SEARCH": { "ACTION": "CENTER_SEARCH" },
        "STATE": {
            data: cachedData("CENTER", "object"),
            loading: false, message: "initail",
            success: false,
        },
        reducer: function (state = REQUESTS.CENTER.STATE, action) {
            return common("CENTER", state, action)
        }
    },
    "LOCATION": {
        "GET": { "URL": "location", "ACTION": "LOCATION_GET" },
        "POST": { "URL": "location", "ACTION": "LOCATION_POST" },
        "PUT": { "URL": "location", "ACTION": "LOCATION_PUT" },
        "PATCH": { "URL": "location", "ACTION": "LOCATION_PATCH" },
        "DELETE": { "URL": "location", "ACTION": "LOCATION_DELETE" },
        "SEARCH": { "ACTION": "LOCATION_SEARCH" },
        "STATE": {
            data: cachedData("LOCATION", "object"),
            loading: false, message: "initail",
            success: false,
        },
        reducer: function (state = REQUESTS.LOCATION.STATE, action) {
            return common("LOCATION", state, action)
        }
    },
    "GEOLOCATION": {
        "GET": { "URL": "geolocation", "ACTION": "GEOLOCATION_GET" },
        "POST": { "URL": "geolocation", "ACTION": "GEOLOCATION_POST" },
        "PUT": { "URL": "geolocation", "ACTION": "GEOLOCATION_PUT" },
        "PATCH": { "URL": "geolocation", "ACTION": "GEOLOCATION_PATCH" },
        "DELETE": { "URL": "geolocation", "ACTION": "GEOLOCATION_DELETE" },
        "SEARCH": {"ACTION": "GEOLOCATION_SEARCH"},
        "STATE": {
            data: cachedData("GEOLOCATION", "object"),
            loading: false, message: "initail",
            success: false,
        },
        reducer: function (state = REQUESTS.GEOLOCATION.STATE, action) {
            return common("GEOLOCATION", state, action)
        }
    },
    "PRODUCT": {
        "GET": { "URL": "product", "ACTION": "PRODUCT_GET" },
        "POST": { "URL": "product", "ACTION": "PRODUCT_POST" },
        "PUT": { "URL": "product", "ACTION": "PRODUCT_PUT" },
        "PATCH": { "URL": "product", "ACTION": "PRODUCT_PATCH" },
        "DELETE": { "URL": "product", "ACTION": "PRODUCT_DELETE" },
        "SEARCH": { "ACTION": "PRODUCT_SEARCH" },
        "STATE": {
            data: cachedData("PRODUCT", "object"),
            loading: false, message: "initail",
            success: false,
        },
        reducer: function (state = REQUESTS.PRODUCT.STATE, action) {
            return common("PRODUCT", state, action)
        }
    },
    "STOCK": {
        "GET": { "URL": "stock", "ACTION": "STOCK_GET" },
        "POST": { "URL": "stock", "ACTION": "STOCK_POST" },
        "PUT": { "URL": "stock", "ACTION": "STOCK_PUT" },
        "PATCH": { "URL": "stock", "ACTION": "STOCK_PATCH" },
        "DELETE": { "URL": "stock", "ACTION": "STOCK_DELETE" },
        "SEARCH": { "ACTION": "STOCK_SEARCH" },
        "STATE": {
            data: cachedData("STOCK", "object"),
            loading: false, message: "initail",
            success: false,
        },
        reducer: function (state = REQUESTS.STOCK.STATE, action) {
            return common("STOCK", state, action)
        }
    },
    "SALE": {
        "GET": { "URL": "sales", "ACTION": "SALE_GET" },
        "POST": { "URL": "sales", "ACTION": "SALE_POST" },
        "PUT": { "URL": "sales", "ACTION": "SALE_PUT" },
        "PATCH": { "URL": "sales", "ACTION": "SALE_PATCH" },
        "DELETE": { "URL": "sales", "ACTION": "SALE_DELETE" },
        "SEARCH": { "ACTION": "SALE_SEARCH" },
        "STATE": {
            data: cachedData("SALE", "object"),
            loading: false, message: "initail",
            success: false,
        },
        reducer: function (state = REQUESTS.SALE.STATE, action) {
            return common("SALE", state, action)
        }
    },
}