import { call, put, takeEvery } from 'redux-saga/effects';
import { get, post, patch, del } from '../actions/api';
import * as action from '../types';
import { isNumber } from '../../components/date/dates';

let ACTION = (action) => {
    return action.replace(/_GET|_POST|_PUT|_PATCH|_DELETE/gi, function (matched) {
        return "";
    }).trim();
}
export function* _search({ type, payload, callback }) {
    try {
        console.log("earch request called", payload)
        const data = yield call(get, payload);
        if (callback) callback(data)
    } catch (error) {
        if (callback) callback([])
    }
}
export function* _get({ type, payload }) {
    type = ACTION(type)
    console.log("ACTION", type)
    let API_URL = action.REQUESTS[type]["GET"]["URL"]
    let url = payload;
    if (isNumber(payload)) {
        payload = payload ? payload : 0;
        url = `${API_URL}/${payload}/`
    }
    console.log("request called", url)
    try {
        const data = yield call(get, url);
        console.log("dsa:", data)
        if (data.error) {
            yield put({ "MODULE": type, "type": type + "_FAILED", "data": data, action: action.DO_NOTHING });
        } else if (data) {
            yield put({ "MODULE": type, "type": type + "_SUCCESS", "data": data, action: action.FILL });
        } else {
            yield put({ "MODULE": type, "type": type + "_FAILED", "data": { error: true, message: "Unknown Error" }, action: action.DO_NOTHING });
        }
    } catch (error) {
        yield put({ "MODULE": type, "type": type + "_FAILED", "data": { error: true, message: "Unknown Error" }, action: action.DO_NOTHING });
    }

}

export function* _post({ type, payload, callback, no_update }) {

    try {
        type = ACTION(type)
        console.log("saveNote", payload)
        let url = action.REQUESTS[type]["POST"]["URL"] + "/"
        if (payload.hasOwnProperty("url")) {
            url = payload.url
            payload = payload.payload
        }
        const data = yield call(post, url, payload);
        if (data.error) {
            console.log("error1:", JSON.stringify(data));
            yield put({ "type": type + "_FAILED", "data": data, action: action.DO_NOTHING });
        } else if (data) {
            console.log("new data - ", data)
            if (!no_update) {
                yield put({ "type": type + "_SUCCESS", "data": data, action: action.APPEND });
                yield put({ "type": type + "_FAILED", "data": { error: false, message: "Data successfully added" }, action: action.DO_NOTHING });
                if (callback) callback(data)
            }else{
                if (callback) callback(data)
            }
        } else {
            console.log("error2:", JSON.stringify(data));
            yield put({ "type": type + "_FAILED", "data": { error: true, message: "Unknown Error" }, action: action.DO_NOTHING });
        }
        console.log("posting...",data)
        if (callback) callback(data)
    } catch (error) {
        console.log("error3:");
        yield put({ "type": action.API_FAILED, "data": { error: true, message: "Unknown Error" }, action: action.DO_NOTHING });
        if (callback) callback({ error: true, message: "Unknown Error" })
    }

}
export function* _put({ type, payload, callback }) {
    type = ACTION(type)
    let API_URL = action.REQUESTS[type]["PUT"]["URL"]
    try {
        console.log("putNote", payload)
        const data = yield call(put, `${API_URL}/${payload.id}/`, payload);
        if (data.error) {
            yield put({ "type": type + "_FAILED", "data": data, action: action.DO_NOTHING });
        } else if (data) {
            console.log("updated data - ", data)
            yield put({ "type": type + "_SUCCESS", "data": data, action: action.REPLACE });
            yield put({ "type": type + "_FAILED", "data": { error: false, message: "" }, action: action.DO_NOTHING });
        } else {
            yield put({ "type": type + "_FAILED", "data": { error: true, message: "Unknown Error" }, action: action.DO_NOTHING });
        }
        if (callback) callback({ error: true, message: "Unknown Error" })
    } catch (error) {
        yield put({ "type": type + "_FAILED", "data": { error: true, message: "Unknown Error" }, action: action.DO_NOTHING });
        if (callback) callback({ error: true, message: "Unknown Error" })
    }
}
export function* _patch({ type, payload, callback }) {
    type = ACTION(type)
    let API_URL = action.REQUESTS[type]["PATCH"]["URL"]
    try {
        console.log("patchNote", payload)
        const data = yield call(patch, `${API_URL}/${payload.id}/`, payload);
        if (data.error) {
            yield put({ "type": type + "_FAILED", "data": data, action: action.DO_NOTHING });
        } else if (data) {
            console.log("patched - ", data)
            yield put({ "type": type + "_SUCCESS", "data": data, action: action.REPLACE });
            yield put({ "type": type + "_FAILED", "data": { error: false, message: "" }, action: action.DO_NOTHING });
        } else {
            yield put({ "type": type + "_FAILED", "data": { error: true, message: "Unknown Error" }, action: action.DO_NOTHING });
        }
        if (callback) callback(data)
    } catch (error) {
        yield put({ "type": type + "_FAILED", "data": { error: true, message: "Unknown Error" }, action: action.DO_NOTHING });
        if (callback) callback({ error: true, message: "Unknown Error" })
    }

}
export function* _del({ type, payload, callback }) {
    type = ACTION(type)
    let API_URL = action.REQUESTS[type]["PATCH"]["URL"]
    try {
        console.log("delete", payload)
        const data = yield call(del, `${API_URL}/`, payload);
        if (data.error) {
            yield put({ "type": type + "_FAILED", "data": data, action: action.DO_NOTHING });
        } else if (data) {
            console.log("deleted data - ", data)
            yield put({ "type": type + "_SUCCESS", "data": data, action: action.REMOVE });
            yield put({ "type": type + "_FAILED", "data": { error: false, message: "" }, action: action.DO_NOTHING });
        } else {
            yield put({ "type": type + "_FAILED", "data": { error: true, message: "Unknown Error" }, action: action.DO_NOTHING });
        }
        if (callback) callback(data)
    } catch (error) {
        yield put({ "type": type + "_FAILED", "data": { error: true, message: "Unknown Error" }, action: action.DO_NOTHING });
        if (callback) callback({ error: true, message: "Unknown Error" })
    }

}