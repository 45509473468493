import React from 'react';
import logo from './logo.svg';
import './App.css';
import Layout from './components/layout/Layout'
import XForm from './components/inputs/XForm';
import XTimeline from './components/basic/XTimeline';
import XLocator from './components/basic/XLocator';
import Box from '@mui/material/Box';
import XGeoLocator from './components/basic/XGeoLocator';
import { useDispatch, useSelector } from 'react-redux';
import { REQUESTS } from './redux/types';

import XMenu from './components/basic/XMenu';
import XLogin from './components/inputs/XForm';
import axios from 'axios';
import { TextField, Button } from '@mui/material';
import XImage from './components/basic/XImage';
import gb from './components/ui/home/bg.gif'

function App() {
  let dispatch = useDispatch();
  let company = useSelector(state => state.company)
  let auth = useSelector(state => state.auth)
  React.useEffect(() => {
    console.log("COMPANY:", company)
  }, [company])
  const authentic = () => {
    if (auth && auth.data && auth && auth.data.token) {
      axios.defaults.headers.common['Authorization'] = "Token " + auth.data.token
    } else {
      axios.defaults.headers.common['Authorization'] = null
    }
  }
  React.useEffect(() => {
    // dispatch({ type: REQUESTS.DASHBOARD.GET.ACTION, payload: 0 })
    // dispatch({ type: REQUESTS.GROUP.GET.ACTION, payload: 0 })
    // dispatch({ type: REQUESTS.PROJECT.GET.ACTION, payload: 0 })
    // dispatch({ type: REQUESTS.PERMISSION.GET.ACTION, payload: 0 })
    authentic()
  }, [])
  React.useEffect(() => {
    console.log("auth changed")
    authentic()
  }, [auth])
  return (
    <>
      <Layout isLogged={auth.data.token != undefined}>
      </Layout>
      {/* <XImage/> */}
      {/* <div style={{ backgroundImage: `url(${gb})`, position: 'absolute', margin: 'auto', left: 0, right: 0, top: 0, bottom: 0, zIndex: -1 }}>
      </div> */}
    </>
  );
}
export default App;
